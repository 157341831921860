import { useTheme } from '@mui/material/styles';
import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";
import Avatar from "components/@extended/Avatar";
import GlobalComponent from "./GlobalComponent";
import { global } from 'data/global';

const SponsorList = ({ data, loading }) => {
    return (
        <>
            {data.map(sponsor => (
                <Grid item xs={12} sm={6} lg={3} key={sponsor.id}>
                    <GlobalComponent
                        name={sponsor.name}
                        data={sponsor.data}
                    />
                </Grid>
            ))}
        </>
    );
}

const GlobalSponsor = () => {
    const [sponsors, setSponsors] = useState([]);
    const [activeFilter, setActiveFilter] = useState(0)
    const [sponsorSelected, setSponsorSelected] = useState("")
    const [kpiLoading, setKpiLoading] = useState(true)
    const [campaignLoading, setCampaignLoading] = useState(true)
    const [campaign, setCampaign] = useState([])
    const [kpi, setKpi] = useState(null);
    const { getSponsorKpi, getCampaignFromSponsor } = useAuth();
    const { getSponsorList, deleteSponsor, changeStatusSponsor } = useAuth();
    const theme = useTheme();
    const { drawerOpen } = useSelector((state) => state.menu);

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true)
            getSponsorKpi(sponsorSelected)
                .then((response) => {
                    // console.log(response.data.data)
                    setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        const fetchCampaign = () => {
            setCampaignLoading(true)
            getCampaignFromSponsor(sponsorSelected)
                .then((response) => {
                    // console.log(response.data.data.campaings)
                    setCampaign(response.data.data.campaings)
                    // setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setCampaignLoading(false)
                })
        }
        if (sponsorSelected !== null && sponsorSelected !== "") {
            fetchKpi()
            fetchCampaign()
        }
    }, [sponsorSelected])

    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])



    useEffect(() => {
        const fetchSponsors = () => {
            getSponsorList()
                .then((response) => {
                    // console.log(response.data.data)
                    setSponsors(response.data.data)
                    try {
                        setSponsorSelected(response.data.data[0].uuid)
                    } catch (error) {
                        console.error(error)
                    }
                    // console.log(sponsors)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }

        fetchSponsors()
    }, [])

    return (
        <Grid container >
            <Grid container xs={12} sm={drawerOpen ? 12 : 10} lg={drawerOpen ? 12 : 10} rowSpacing={1} columnSpacing={0.5}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography className="font-semibold text-2xl">Vista global de sponsors</Typography>
                    <Typography variant="body1" className="text-[#606A82] pt-4">Realiza un avistamiento rápido de todos los sponsors por país en toda la región, úsalo</Typography>
                    <Typography variant="body1" className="text-[#606A82]">como un filtro general.</Typography>
                </Grid>
                <SponsorList data={global} loading={campaignLoading} />
            </Grid>
        </Grid>
    );
}

export default GlobalSponsor;