import LeadLayout from "layout/LeadLayout";
import LeadBoard from "pages/lead/LeadBoard";
import LeadConfig from "pages/lead/LeadConfig";
import LeadPage from "pages/lead/LeadPage";
import AuthGuard from "utils/route-guard/AuthGuard";

const LeadRoutes = {
    path: '/lead',
    element: (
        <AuthGuard>
            <LeadLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <LeadPage />
        },
        {
            path: 'board',
            children: [
                {
                    path: ':campaignId',
                    element: <LeadBoard />
                }
            ]
        },
    ]
};

export default LeadRoutes;
