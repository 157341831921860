import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    Box,
    Button,
    Chip,
    Dialog,
    Grid,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
    CSVExport,
    HeaderSort,
    IndeterminateCheckbox,
    SortingSelect,
    TablePagination,
    TableRowSelection
} from 'components/third-party/ReactTable';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
import esLocale from 'date-fns/locale/es';
import call from 'assets/images/vap/call.png';
import like from 'assets/images/vap/like.png';
import dislike from 'assets/images/vap/dislike.png';
import likeoff from 'assets/images/vap/likeoff.png';
import dislikeoff from 'assets/images/vap/dislikeoff.png';
import { startOfMonth, endOfMonth, format } from 'date-fns';

// assets
import { Add, Call, DocumentDownload, Edit, Eye, Messages3, Personalcard, PresentionChart, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import CustomTooltip from 'components/@extended/Tooltip';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatDateShort, formatedDate, formatID, shortDate } from 'utils/appUtils';
import AlertSponsorDelete from 'pages/sponsor/AlertSponsorDelete';
import AlertSponsorStatusChange from 'pages/sponsor/AlertSponsorStatusChange';
import SponsorView from 'pages/sponsor/SponsorView';
import useDataStore from 'store/useDataStore';
import { useNavigate, useParams } from 'react-router';
import PlayAudio from './PlayAudio';
import AlertCallRejected from './AlertCallRejected';
import { useRef } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FormattedDate } from 'react-intl';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { isObject } from 'lodash';


const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd, count, selectedPage, handleChangePage, rowsPerPage, handleChangeRowsPerPage, handleDownload }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    return (
        <>
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        {/*<CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'sponsors-list.csv'} />*/}
                        <IconButton onClick={handleDownload}><DocumentDownload size="60" color="#FF8A65" /></IconButton>
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                            page.map((row, i) => {
                                prepareRow(row);
                                const rowProps = row.getRowProps();

                                return (
                                    <Fragment key={i}>
                                        <TableRow
                                            {...row.getRowProps()}
                                            // onClick={() => {
                                            //     row.toggleRowSelected();
                                            // }}
                                            sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                        >
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                    </Fragment>
                                );
                            })
                        ) : (
                            // Renderiza esta fila si no hay datos
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No hay registros de llamadas disponibles
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                {/*<TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />*/}
                                <MuiTablePagination
                                    component="div"
                                    count={count}
                                    page={selectedPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}
const CallList = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [sponsors, setSponsors] = useState([]);
    const [auditedCalls, setAuditedCalls] = useState([]);
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const [add, setAdd] = useState(false);
    const { getAffectedCalls, getAuditedCalls, createAudioFeedback, deleteSponsor, changeStatusSponsor, getAffectedCallExcell, getAuditedCallExcell } = useAuth();
    const [auditedLoading, setAuditedLoading] = useState(false);
    const [callsLoading, setCallsLoading] = useState(false);
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);
    const { campaignSelected, setCampaignSelected, selectedData, setSelectedData } = useDataStore();
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const [selectedAudio, setSelectedAudio] = useState(null);
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'));
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

    const [page2, setPage2] = useState(0);
    const [rowsPerPage2, setRowsPerPage2] = useState(10);
    const [totalRows2, setTotalRows2] = useState(0);

    const handleChangePage = (event, newPage) => {
        console.log(`Pagination: ${newPage}`);
        setPage(newPage);
        //fetchAuditedCalls(newPage + 1, rowsPerPage);
        fetchSponsors(newPage + 1, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        //fetchAuditedCalls(1, rowsPerPage);
        fetchSponsors(1, rowsPerPage);
    };

    const handleChangePage2 = (event, newPage) => {
        console.log(`Pagination: ${newPage}`);
        setPage2(newPage);
        fetchAuditedCalls(newPage + 1, rowsPerPage2);
    };

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
        fetchAuditedCalls(1, rowsPerPage2);
    };

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsors', path: `/vap/${selectedData?.sponsor?.uuid || ""}` },
            { label: campaignSelected.sponsor.name || "", path: `/vap/${campaignSelected?.sponsor.uuid || ""}` },
            { label: 'Campañas', path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            //{ label: selectedData?.campaign?.name || "", path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            { label: campaignSelected.campaign.name || "", path: `/vap/all-campaign/${campaignSelected?.campaign.uuid || ""}` },
            { label: 'Llamadas afectadas' || "", path: `/vap/campaigns/calls` }
        ]);
        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, selectedData]);

    const addSponsor = (sponsor) => {
        // console.log([...sponsors, sponsor])
        setSponsors([...sponsors, sponsor])
    }

    const updateSponsor = (updatedSponsor) => {
        setSponsors(prevSponsors =>
            prevSponsors.map(sponsor =>
                sponsor.uuid === updatedSponsor.uuid ? updatedSponsor : sponsor
            )
        );
    }

    const handleLike = (id) => {
        const object = {
            agent_audio_data_id: id,
            //text_call_feedback_id: '',
            status: 'approved'
        }
        createAudioFeedback(object)
            .then((response) => {
                console.log(response.data)
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Llamada aprobada exitosamente.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                fetchSponsors();
                fetchAuditedCalls();
            }).catch((error) => {
                console.error(error)
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Ha ocurrido un error al aprobar la llamada.',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }).finally(() => { })
    }

    const refresh = () => {
        fetchSponsors();
        fetchAuditedCalls();
    }

    const handleAdd = (edit = false, reason) => {
        if (reason !== 'backdropClick') {
            setAdd(!add);
            if (!edit) setCustomer(null);
        }

        // togglePlayPause()
    };

    const getUuidById = (id) => {
        // console.log(id)
        const foundObject = sponsors.find(item => item.id === id);
        // console.log(foundObject)
        return foundObject ? foundObject.uuid : "";
    };

    const handleClose = (action) => {
        setOpen(!open);
        if (action) {
            deleteSponsor(getUuidById(customerDeleteId))
                .then((response) => {
                    fetchSponsors()
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Campaña eliminada correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                }).catch((error) => {
                    console.error(error)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al eliminar campaña.',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
    };

    const fetchSponsors = (page = 1, pagination = 10) => {
        setCallsLoading(true);
        getAffectedCalls(campaignId, page, pagination, startDate, endDate)
            .then((response) => {
                console.log(response.data.data)
                setSponsors(response.data.data.data)
                setTotalRows(response.data.data.total)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setCallsLoading(false);
            })
    }

    const fetchAuditedCalls = (page = 1, pagination = 10) => {
        setAuditedLoading(true);
        getAuditedCalls(campaignId, page, pagination, startDate, endDate)
            .then((response) => {
                console.log(response)
                setAuditedCalls(response.data.data.data)
                setTotalRows2(response.data.data.total)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                //setLoadingData(false)
                setAuditedLoading(false);
            })
    }

    useEffect(() => {
        fetchSponsors()
        fetchAuditedCalls()
    }, [selectedDate]);

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (array[i][index]) {
                    if (line !== '') line += ',';
                    const cell = isObject(array[i][index]) ? (array[i][index].name ? array[i][index].name : array[i][index].summary_rejection ? array[i][index].summary_rejection : (array[i][index].status ? array[i][index].status : array[i][index])) : array[i][index];
                    line += cell;
                }
            }
            str += line + '\r\n';
        }
        return str;
    };

    const downloadCSV = (jsonData, fileName = 'calls-list') => {
        const csvData = new Blob([convertToCSV(jsonData)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        console.log('Downloading')
        getAffectedCallExcell(campaignId, 1, 10, startDate, endDate)
            .then((response) => {
                console.log(response);
                downloadCSV(response.data.data, 'calls-list');
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                console.log('HandleDownload has been executed')
            });
    }

    const handleDownload2 = () => {
        console.log('Downloading')
        getAuditedCallExcell(campaignId, 1, 10, startDate, endDate)
            .then((response) => {
                console.log(response);
                downloadCSV(response.data.data, 'calls-list');
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                console.log('HandleDownload has been executed')
            });
    }

    const columns1 = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                return (
                    <Typography variant="body1">{formatID(row.values.id)}</Typography>
                );
            }
        },
        {
            Header: 'LEAD ID',
            accessor: 'lead_id',
            className: 'cell-center',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Typography variant="body1">{values.call_affected?.lead_id || ''}</Typography>
                );
            }
        },
        {
            Header: 'AUDIO',
            accessor: 'name',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Typography className='text-left text-orange-400 font-medium' variant="subtitle1">{values.name}</Typography>
                    </Stack>
                );
            }
        },
        {
            Header: 'FECHA',
            accessor: 'date',
            Cell: ({ row }) => {
                const { original } = row;
                return (<p>{formatDateShort(original.date || "")}</p>);
            }
        },
        {
            Header: 'AGENTE',
            accessor: 'agent',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (<p className='text-center font-semibold'>{values.agent?.name || ""}</p>);
            }
        },
        {
            Header: 'RESUMEN RECHAZO',
            accessor: '',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <div className='flex flex-col items-start'>
                        <p>{values.call_affected?.summary_rejection || values.summary_rejection || ''}</p>
                    </div>
                );
            }
        },
        {
            Header: 'PUNTAJE DE LLAMADA',
            accessor: 'general_score',
            Cell: ({ row }) => {
                const { values } = row;
                return <p className='text-center font-semibold'>{values?.general_score || ""}</p>
            }
        },
        {
            Header: 'Link Llamada',
            accessor: 'decode_transcription',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setCustomer(values);
                                setSelectedAudio(values);
                                handleAdd(true);
                            }}>
                            <img className='w-10' src={call} alt="Escuchar audio" />
                        </IconButton>
                    </Stack>
                )
            }
        },
        {
            Header: 'FEEDBACK',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Aprobar"
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleLike(values.id)
                                }}
                            >
                                <img src={like} alt="Aprobar" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Reprobar"
                        >
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    //handleAdd(true);
                                    setCustomer(values);
                                    //handleDislike(values.id, 1)
                                    setOpen(true);
                                }}
                            >
                                <img src={dislike} alt="Reprobar" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        }
    ], [theme]);

    const columns2 = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                return (
                    <Typography variant="body1">{formatID(row.values.id)}</Typography>
                );
            }
        },
        {
            Header: 'LEAD ID',
            accessor: 'call_affected',
            className: 'cell-center',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Typography variant="body1">{values.call_affected?.lead_id || ''}</Typography>
                );
            }
        },
        {
            Header: 'AUDIO',
            accessor: 'name',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Typography className='text-left text-orange-400 font-medium' variant="subtitle1">{values.name}</Typography>
                    </Stack>
                );
            }
        },
        {
            Header: 'FECHA',
            accessor: 'date',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (<p>{formatDateShort(values.date || "")}</p>);
            }
        },
        {
            Header: 'AGENTE',
            accessor: 'agent',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (<p className='text-center font-semibold'>{values.agent?.name || ""}</p>);
            }
        },
        {
            Header: 'RESUMEN RECHAZO',
            accessor: '',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <div className='flex flex-col items-start'>
                        <p>{values.call_affected?.summary_rejection || values.summary_rejection || ''}</p>
                    </div>
                );
            }
        },
        {
            Header: 'PUNTAJE DE LLAMADA',
            accessor: 'general_score',
            Cell: ({ row }) => {
                const { values } = row;
                return <p className='text-center font-semibold'>{values?.general_score || ""}</p>
            }
        },
        {
            Header: 'DESCRIPCIÓN',
            Cell: ({ row }) => {
                const { values } = row;
                //console.log(values)
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Typography variant='body1'>{values.call_feedback?.status === "approved" ? 'Aprobada' : values.call_feedback?.text_call_feedback?.text_feedback || 'Reprobada'}</Typography>
                    </Stack>
                )
            }
        },
        {
            Header: 'Link Llamada',
            accessor: 'decode_transcription',
            Cell: ({ row }) => {
                const { values } = row;
                console.log(values)
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setCustomer(values);
                                setSelectedAudio(values)
                                // handleAdd(true);
                                setAdd(true);
                            }}>
                            <img className='w-10' src={call} alt="Escuchar audio" />
                        </IconButton>
                    </Stack>
                )
            }
        },
        {
            Header: 'FEEDBACK',
            accessor: 'call_feedback',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Aprobar"
                        >
                            <IconButton
                                disabled
                            >
                                <img src={values.call_feedback?.status === "approved" ? like : likeoff} alt="Aprobar" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Reprobar"
                        >
                            <IconButton
                                disabled
                            >
                                <img src={values.call_feedback?.status === "rejected" ? dislike : dislikeoff} alt="Reprobar" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        },
        {
            Header: 'AUDITOR',
            //accessor: row => <Avatar alt="Avatar 1" size="sm" src={row.logo} />,
            Cell: ({ row }) => {
                const { values } = row;
                if (values.call_feedback?.user_feedback?.profile_img) {
                    return (
                        <Avatar alt={values.call_feedback?.user_feedback?.name || "Avatar 1"} size="sm" src={values.call_feedback?.user_feedback?.profile_img} />
                    );
                } else {
                    return (<Avatar alt={values.call_feedback?.user_feedback?.name || ''} size="sm">{values.call_feedback?.user_feedback?.name?.charAt(0).toUpperCase()}</Avatar>
                    );
                }
            }
        },
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <SponsorView data={sponsors[Number(row.id)]} />, [sponsors]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const handleConversationClick = () => {
        navigate(`/vap/voice-analyzer/${campaignSelected?.campaign?.uuid}`);
    }

    const handleStatsClick = () => {
        setCampaignSelected({
            campaign: campaignSelected?.campaign,
            sponsor: campaignSelected?.sponsor
        });
        navigate(`/vap/all-campaign/stats/${campaignSelected?.campaign?.uuid}`);
    }

    const handleAgentsClick = () => {
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleDateChange = (date) => {
        const formattedStartDate = format(startOfMonth(date), 'yyyy-MM-dd');
        const formattedEndDate = format(endOfMonth(date), 'yyyy-MM-dd');
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setSelectedDate(date);
    };

    return (
        <>
            <Grid item xs={12} md={12} lg={12}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretch', sm: 'center' }}
                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                    spacing={2}
                >
                    <Stack>
                        <Typography variant='subtitle1' gutterBottom>Datos del Mes:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <Box sx={{ minWidth: 220 }}>
                                <DatePicker
                                    views={['month', 'year']}
                                    minDate={new Date('2024-01-01')}
                                    maxDate={new Date('2030-12-31')}
                                    value={selectedDate}
                                    onChange={(newValue) => handleDateChange(newValue)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                        spacing={2}
                        sx={{ width: '100%', mt: { xs: 2, sm: 0 } }}
                    >
                        <Button variant="contained" startIcon={<Call />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold'>
                            Llamadas afectadas
                        </Button>
                        <Button variant="contained" startIcon={<Messages3 />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleConversationClick}>
                            Conversaciones
                        </Button>
                        <Button variant="contained" startIcon={<PresentionChart />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleStatsClick}>
                            Estadísticas
                        </Button>
                        <Button variant="contained" startIcon={<Personalcard />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleAgentsClick}>
                            Agentes
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
            <Typography className='font-medium text-xl py-4'>Llamadas afectadas</Typography>
            <MainCard content={false}>
                <ScrollX>
                    <ReactTable columns={columns1} data={sponsors} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} selectedPage={page} handleChangeRowsPerPage={handleChangeRowsPerPage} handleChangePage={handleChangePage} rowsPerPage={rowsPerPage} count={totalRows} handleDownload={handleDownload} />
                    {callsLoading && <BorderLinearProgress variant="indeterminate" value={50} />}
                </ScrollX>
                <AlertCallRejected id={customer?.id} open={open} refresh={refresh} handleClose={() => setOpen(false)} />
            </MainCard>
            <Typography className='font-medium text-xl pb-4 pt-8'>Llamadas auditadas</Typography>
            <MainCard content={false}>
                <ScrollX>
                    {/*<ReactTable columns={columns2} data={auditedCalls} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} handlePageChange={handleChangePage} />*/}
                    <ReactTable columns={columns2} data={auditedCalls} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} selectedPage={page2} handleChangeRowsPerPage={handleChangeRowsPerPage2} handleChangePage={handleChangePage2} rowsPerPage={rowsPerPage2} count={totalRows2} handleDownload={handleDownload2} />
                    {auditedLoading && <BorderLinearProgress variant="indeterminate" value={50} />}
                </ScrollX>
                <Dialog
                    maxWidth="sm"
                    TransitionComponent={PopupTransition}
                    keepMounted
                    fullWidth
                    disableEscapeKeyDown
                    onClose={handleAdd}
                    open={add}
                    sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <PlayAudio customer={customer} onCancel={handleAdd} selectedAudio={selectedAudio} audioRef={audioRef} isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
                </Dialog>
            </MainCard>
        </>
    );
}

export default CallList;