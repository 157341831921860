import { AvatarGroup, Divider, Typography, Chip, LinearProgress, Skeleton } from '@mui/material';
import { ThemeMode } from 'config';
import { useTheme } from '@mui/material/styles';
import Avatar from 'components/@extended/Avatar';
import { sponsors } from 'data/sponsors';
import { ArrowDown, ArrowDown3, ArrowRight3, ArrowUp, Calendar, CloudChange, Element } from 'iconsax-react';
import MainCard from 'components/MainCard';
import CustomTooltip from 'components/@extended/Tooltip';
import { useNavigate } from 'react-router-dom';

const getColorProps = (serverStatus) => {
    switch (serverStatus) {
        case null:
        case undefined:
            return { background: '#6610F221', barColor: '#6610F221' };
        case 'PROCESS':
            return { background: '#565CF8', barColor: '#6A82FB' };
        case 'UPLOADING':
            return { background: '#FEBF20', barColor: '#FFD700' };
        case 'FINISHED':
            return { background: '#06BF7B', barColor: '#00FA9A' };
        case 'ACTIVE':
        case 'ACTIVO':
            return { background: '#06BF7B21', barColor: 'rgb(22 163 74)' };
        case 'INACTIVE':
        case 'INACTIVO':
            return { background: '#FEBF20', barColor: '#FFD700' };
        default:
            return { background: '#9399AA', barColor: '#B3B6B7' };
    }
}

const CountryName = ({loading, name}) => {
    if (loading) {
        return (<span className='text-sm text-start font-semibold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></span>);
    } else {
        return (<span className='text-sm text-start font-semibold'>{name}</span>);
    }
}

const CountryValue = ({loading, value}) => {
    if (loading) {
        return (<p className='text-sm font-bold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></p>);
    } else {
        return (<p className='text-xs font-normal text-end'><span className='text-[#E26205]'>{value || 0}</span> Sponsors</p>);
    }
}

const getStatusProps = (serverStatus) => {
    switch (serverStatus) {
        case null || undefined:
            return { label: "Sin audios", className: "bg-[#EA1F6321] text-pink-800" };
        case 'PROCESS':
            return { label: "Procesando", className: "bg-[#565CF821] text-blue-800" };
        case 'UPLOADING':
            return { label: "Cargando", className: "bg-[#FEBF20] text-yellow-800" };
        case 'FINISHED':
            return { label: "Completado", className: "bg-[#06BF7B21] text-green-500" };
        case 'ACTIVE':
            return { label: "Activo", className: "bg-[#06BF7B21] text-green-500" };
        case 'INACTIVE':
            return { label: "Activo", className: "bg-[#FEBF20] text-yellow-800" };
        case 'ACTIVO':
            return { label: "Activo", className: "bg-[#06BF7B21] text-green-500" };
        case 'INACTIVO':
            return { label: "Activo", className: "bg-[#FEBF20] text-yellow-800" };
        default:
            return { label: serverStatus, className: "bg-[#9399AA21] text-slate-800" };
    }
}


const GlobalComponent = ({ name, data, status, loading, id, percentageMinutesProcess, tmo, percentageTmo, totalCallsAffected, totalBuyAcepted, callQuality, mac }) => {
    const theme = useTheme();
    const { label, className } = getStatusProps(status);
    const { background, barColor } = getColorProps(status);
    const navigate = useNavigate();
    const handleCampaignClick = (campaign) => {
        navigate(`/vap/all-campaign/${campaign}`);
    }

    return (
        <MainCard className='shadow-md  hover:cursor-pointer' onClick={() => { handleCampaignClick(id) }}>
            <div className='flex flex-row items-center justify-between pb-4'>
                <Typography variant="body1" className='ml-1 text-base font-bold'>{loading ? <Skeleton width={60} /> : name}</Typography>
            </div>

            <Divider />

            <Typography variant="body1" className='ml-1 text-sm font-medium text-[#E26205] pt-4'>{loading ? <Skeleton width={60} /> : 'Por país'}</Typography>

            <div className='flex flex-row pt-3 justify-between'>
                <div className='flex flex-col justify-center text-start'>
                    { data.map(country => (<CountryName key={Math.random()} loading={loading} name={country.name} />)) }
                </div>

                <div className='flex flex-col justify-center'>
                    { data.map(country => (<CountryValue key={Math.random()} loading={loading} value={country.value} />)) }
                </div>
            </div>
        </MainCard>
    );
}

export default GlobalComponent;