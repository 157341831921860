import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Skeleton, Card, Badge, Button, Chip, Divider, ButtonGroup, TextField, CircularProgress, Tooltip } from '@mui/material';
import { ThemeMode } from 'config';

// assets
import { ArrowDown, ArrowDown2, ArrowUp, ArrowUp2, Calendar, Tag2, CloudChange, ArrowRight2, Message, SearchNormal1, Add, TickCircle, DocumentText1, Messages3, PresentionChart, Personalcard, Call, SearchNormal, CloseCircle, RepeatCircle, RecoveryConvert, Check, Money4 } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import AgentStatsRadialChart from './AgentStatsRadialChart';
import Avatar from 'components/@extended/Avatar';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { esES } from '@mui/material/locale';
import { useRef } from 'react';
import AudioPlayer from './AudioPlayer';
import ChatComponent from './ChatComponent';
import messages from 'data/messages';
import AudioSelectorModal from './AudioSelectorModal';
import useDataStore from 'store/useDataStore';
import LoadingAgent from './LoadingAgent';
import FloatingAudioControl from './FloatingAudioControl';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { useMemo } from 'react';
import AgentList from './AgentList';
import { mockConversation } from 'utils/mockConversation';
const avatarImage = require.context('assets/images/users', true);
const getFirstDayOfMonth = () => {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

const formatDate = (date) => {
    try {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    } catch (err) {
        return date
    }
};

const VapIndex = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { campaignId, agentId } = useParams();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { getCampaignKpi, getCampaignFromSponsor, getCampaignAgents, getAudioFromAgentCampaign, getAudioData, getAudioFile } = useAuth();
    const { campaignSelected, setCampaignSelected, selectedData, setSelectedData } = useDataStore();
    const [agents, setAgents] = useState([])
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);
    const [tree, setTree] = useState([]);
    const [kpi, setKpi] = useState(null);
    const [loadingAgent, setLoadingAgent] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [loadingAudios, setLoadingAudios] = useState(false);
    const [audioData, setAudioData] = useState();
    const [loadingAudioData, setLoadingAudioData] = useState(false);
    const [audios, setAudios] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [recomendations, setRecomendations] = useState([]);
    const [audioSrc, setAudioSrc] = useState(null);
    const [loadingAudioSrc, setLoadingAudioSrc] = useState(false);
    const [selectedDate, setSelectedDate] = useState({
        startDate: getFirstDayOfMonth(),
        endDate: null
    });
    const [openPicker, setOpenPicker] = useState(null);
    const [chartData, setChartData] = useState([0, 0, 0, 0]);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [manualChangeTime, setManualChangeTime] = useState(null);
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const [kpiLoading, setKpiLoading] = useState(true);
    const [filteredAgents, setFilteredAgents] = useState([]);
    const [filter, setFilter] = useState('');
    const [macTime, setMacTime] = useState(null);
    const [priceTime, setPriceTime] = useState(null);
    const [lastFilter, setlastFilter] = useState(null);
    const [messages, setMessages] = useState(null);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsors', path: `/vap/${selectedData?.sponsor?.uuid || ""}` },
            { label: selectedData?.sponsor?.name || "", path: `/vap/${selectedData?.sponsor?.uuid || ""}` },
            { label: 'Campañas', path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            { label: selectedData?.campaign?.name || "", path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            { label: 'Agentes', path: `/vap/agents/list` },
            { label: selectedAgent?.name || "", path: `/vap/voice-analyzer/${selectedData?.campaign?.uuid || ""}/${selectedAgent?.id}` }
        ]);
        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, selectedData, selectedAgent]);

    const handleAgentSelect = (agent) => {
        setAudios(null);
        setSelectedAgent(agent);
    };

    const handleFilterChat = (filter) => {
        if (filter === lastFilter) {
            setMessages(audioData?.decode_transcription?.paragraphs);
            setlastFilter(null);
        } else {
        let paragraphs = [];
        audioData?.decode_transcription?.paragraphs.map((paragraph) => {
            let newParagraph = {...paragraph};
            newParagraph.sentences = [];
            paragraph.sentences.map((sentence) => {
                console.log(sentence[filter]);
                if(sentence[filter]) {
                    newParagraph.sentences.push(sentence);
                }
            });
            if(newParagraph.sentences) {
                paragraphs.push(newParagraph);
            }
        });
        setMessages(paragraphs);
        setlastFilter(filter);
    }
};

    const handleKeyDown = (event, filter) => {
        if (event.key === 'Enter') {
            handleFilterChat(filter);
        }
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const fetchAgents = () => {
        setLoadingAgent(true);
        getCampaignAgents(campaignSelected.campaign.uuid)
            .then((response) => {
                const updatedAgents = response.data.data.map(agent => ({
                    ...agent,
                    img: avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`)
                }));

                const agentsWithAudios = updatedAgents.filter(agent => agent.audios_count > 0);
                const agentsWithoutAudios = updatedAgents.filter(agent => agent.audios_count === 0);

                const sortedAgentsWithAudios = agentsWithAudios.sort((a, b) => a.name.localeCompare(b.name));
                const sortedAgentsWithoutAudios = agentsWithoutAudios.sort((a, b) => a.name.localeCompare(b.name));

                const sortedAgents = [...sortedAgentsWithAudios, ...sortedAgentsWithoutAudios];
                // console.log(sortedAgents)
                setAgents(sortedAgents);
                setFilteredAgents(sortedAgents);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                setLoadingAgent(false);
            });
    };

    useEffect(() => {
        if (campaignSelected?.campaign) {
            fetchAgents();
        }
    }, [campaignSelected]);

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true);
            getCampaignKpi(campaignSelected.campaign.uuid)
                .then((response) => {
                    setKpi(response.data.data);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setKpiLoading(false);
                });
        };
        const fetchCampaign = () => {
            setKpiLoading(true);
            getCampaignFromSponsor(campaignSelected.sponsor.uuid)
                .then((response) => {
                    const foundCampaign = response.data.data.campaings.find(campaign => campaign.uuid.toString() === campaignSelected.campaign.uuid);
                    setSelectedData({ sponsor: response.data.data, campaign: foundCampaign });
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setKpiLoading(false);
                });
        };

        if (campaignSelected?.campaign) {
            fetchKpi();
            fetchCampaign();
        }
    }, [campaignSelected, setSelectedData]);

    useEffect(() => {
        const fetchAudioFromAgentCampaign = () => {
            setLoadingAudios(true);
            getAudioFromAgentCampaign(campaignSelected.campaign.uuid, selectedAgent.uuid, selectedDate?.startDate?.toISOString().split('T')[0], selectedDate?.endDate?.toISOString().split('T')[0])
                .then((response) => {
                    setChartData([0, 0, 0, 0]);
                    setSelectedAudio(null);
                    setAudioSrc(null);
                    setAudioData(null);
                    setAudios(response.data.data);
                    setlastFilter(null);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setLoadingAudios(false);
                });
        };

        if (campaignSelected && selectedAgent) {
            fetchAudioFromAgentCampaign();
        }
    }, [campaignSelected, selectedAgent, selectedDate]);

    useEffect(() => {
        const fetchAudioData = () => {
            setLoadingAudioData(true);
            getAudioData(selectedAudio?.id)
                .then((response) => {
                    try {
                        const percentageKeys = ['unmissable_percentage', 'not_allowed_percentage', 'sales_arguments_percentage', 'sales_acceptance'];
                        const extractedData = percentageKeys.map(key => response.data.data[key] || 0);
                        setChartData(extractedData);
                    } catch (error) {
                        console.error(error);
                        setChartData([0, 0, 0, 0]);
                    }
                    // console.log(response.data.data)
                    setlastFilter(null);
                    setAudioData(response.data.data);
                    //setAudioData(mockConversation.data)
                    setMessages(response.data.data?.decode_transcription?.paragraphs);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setLoadingAudioData(false);
                });
        };

        const fetchAudioFile = () => {
            setLoadingAudioSrc(true);
            getAudioFile(selectedAudio?.id)
                .then((response) => {
                    setAudioSrc(response.data.data);
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setLoadingAudioSrc(false);
                });
        };

        if (selectedAudio) {
            fetchAudioData();
            fetchAudioFile();
        }
    }, [selectedAudio]);

    const handleDateChange = (date, key) => {
        setSelectedDate({ ...selectedDate, [key]: date });
        setOpenPicker(null);
    };

    const handleSearch = () => {
        if (filter) {
            const lowercasedFilter = filter.toLowerCase();
            const filteredData = agents?.filter(agent =>
                agent?.name.toLowerCase().includes(lowercasedFilter)
            );
            setFilteredAgents(filteredData);
        } else {
            setFilteredAgents(agents);
        }
    };

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = (newTime) => {
        setCurrentTime(newTime);
    };

    const handleManualChangeTime = (value) => {
        setManualChangeTime(value);
    };

    const handleTimeLeap = (value) => {
        setCurrentTime(value);
        setManualChangeTime(value);
    };

    const handleCallsClick = () => {
        navigate(`/vap/campaigns/calls/${campaignSelected?.campaign?.uuid}`);
    }

    const handleStatsClick = () => {
        setCampaignSelected({
            campaign: campaignSelected?.campaign,
            sponsor: campaignSelected?.sponsor
        });
        navigate(`/vap/all-campaign/stats/${campaignSelected?.campaign?.uuid}`);
    }

    const handleAgentsClick = () => {
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handlePickerOpen = (key, ref) => {
        setOpenPicker(key);
    };

    return (
        <Grid container spacing={1}>
            {/* {!drawerOpen && (
                <Grid item xs={12} sm={2} lg={2} className={`border-[0.1px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-zinc-900' : 'bg-white'} min-h-screen`}>
                    <div className={`flex flex-col justify-center items-start`}>
                        <p className='font-semibold mt-4 text-sm ml-4'>Lista de campañas:</p>
                        <Box>
                            <MenuCampaignThread tree={tree} handleListItemClick={handleListItemClick} campaignSelected={campaignSelected} open={open} handleClick={handleClick} />
                        </Box>
                    </div>
                </Grid>
            )} */}
            {/* <Grid item xs={12} sm={drawerOpen ? 12 : 10} lg={drawerOpen ? 12 : 10} > */}

            <Grid item xs={12} md={12} lg={12}>
                <div className='flex flex-row justify-between items-center'>
                    <div className="flex items-center mb-4">
                        <TextField
                            variant="outlined"
                            placeholder="Buscar agente..."
                            value={filter}
                            InputProps={{
                                startAdornment: (
                                    <SearchNormal color={`${theme.palette.mode === ThemeMode.DARK ? "#fff" : "#000"}`} />
                                ),
                                endAdornment: (
                                    filter !== "" && (
                                        <IconButton onClick={() => {
                                            setFilter('');
                                            setFilteredAgents(agents);
                                        }}>
                                            <CloseCircle variant='Bold' color={`${theme.palette.mode === ThemeMode.DARK ? "#fff" : "#000"}`} />
                                        </IconButton>
                                    )
                                )
                            }}
                            sx={{
                                minWidth: "350px"
                            }}
                            onChange={(e) => {
                                if (e.target.value !== "") {
                                    setFilter(e.target.value)
                                } else {
                                    setFilter('');
                                    setFilteredAgents(agents);
                                }
                            }}
                            onKeyDown={handleKeyPress}
                        />
                        <Button variant="contained" onClick={handleSearch} className="ml-2 bg-orange-500">
                            Buscar
                        </Button>
                    </div>
                    <Stack direction='row' alignItems="center" spacing={2}>
                        <div className='flex flex-row justify-end w-full py-4 mr-4 space-x-2'>
                            <Button variant="contained" startIcon={<Call />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleCallsClick}>
                                Llamadas afectadas
                            </Button>
                            <Button variant="contained" startIcon={<Messages3 />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold'>
                                Conversaciones
                            </Button>
                            <Button variant="contained" startIcon={<PresentionChart />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleStatsClick}>
                                Estadisticas
                            </Button>
                            <Button variant="contained" startIcon={<Personalcard />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={(e) => {
                                handleAgentsClick()
                                e.stopPropagation()
                            }}>
                                Agentes
                            </Button>
                        </div>
                    </Stack>
                </div>
            </Grid>
            <Grid item xs={12} md={0.8} lg={0.8} className={`border-[0.3px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} h-[1090px] mr-2 mt-2`}>
                <div className={`flex flex-col overflow-y-auto items-center pt-2 h-full ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"} `}>
                    <div className='grow flex flex-col space-y-4 mb-1 items-center mt-2'>
                        {loadingAgent ? (
                            <LoadingAgent />
                        ) : (
                            <AgentList
                                agents={filteredAgents}
                                handleAgentSelect={handleAgentSelect}
                                selectedAgent={selectedAgent}
                                theme={theme}
                            />
                        )}
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} md={8} lg={8} className={`border-[0.3px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} mt-2 h-max `}>
                <div className="flex flex-col p-2 min-h-screen max-w-full">
                    <div className='flex flex-row items-center mb-4 space-x-2 w-full max-w-full overflow-x-clip'>
                        <span className={`text-xs font-semibold mr-2 ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>Filtrar por:</span>
                        <ButtonGroup variant="outlined" aria-label="text button group" color="secondary" className='text-xs mr-4'>
                            <Button key="one" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-xs font-semibold`}>Fecha</span></Button>
                            <Button key="two" ref={startDateRef} onClick={() => handlePickerOpen('startDate')}>
                                <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-nowrap`}>
                                    {selectedDate.startDate ? new Date(selectedDate.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : formatDate(selectedDate.startDate)}
                                </span>
                            </Button>
                            <Button key="none" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs`}>-</span></Button>
                            <Button key="three" ref={endDateRef} onClick={() => handlePickerOpen('endDate')}>
                                <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-nowrap`}>
                                    {selectedDate.endDate ? selectedDate.endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                </span>
                            </Button>
                        </ButtonGroup>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <MobileDatePicker
                                open={openPicker === 'startDate'}
                                onClose={() => setOpenPicker(null)}
                                value={selectedDate.startDate}
                                onChange={(date) => handleDateChange(date, 'startDate')}
                                renderInput={(params) => <TextField {...params} style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }} />}
                                inputFormat="yyyyy-MM-dd"
                                sx={{ display: "none" }}
                            />
                            <MobileDatePicker
                                open={openPicker === 'endDate'}
                                onClose={() => setOpenPicker(null)}
                                value={selectedDate.endDate}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                                renderInput={(params) => <TextField {...params} style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }} />}
                                inputFormat="yyyyy-MM-dd"
                                sx={{ display: "none" }}
                            />
                        </LocalizationProvider>
                        <ButtonGroup variant="outlined" aria-label="text button group" color="secondary" sx={{ ml: 2 }} className="max-w-fit">
                            <Button key="one" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-black text-xs font-semibold`}>Audio</span></Button>
                            <Button key="two" onClick={handleOpenModal}>
                                {!loadingAudios ? (
                                    <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-ellipsis text-nowrap`}>
                                        {selectedAudio ? selectedAudio.name.length > 45 ? `${selectedAudio.name.substring(0, 30)}...` : selectedAudio.name : 'Seleccionar un audio...'}
                                    </span>
                                ) : (
                                    <Skeleton varian="rounded" width={150} className="text-xs" />
                                )
                                }
                            </Button>
                        </ButtonGroup>
                        {audioData && audioData?.concatenated === 1 && (
                            <Box  sx={{ px: 2 }}>
                                <Tooltip
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                                opacity: 0.9
                                            }
                                        }
                                    }}
                                    className="hover:cursor-pointer"
                                    title="Audio Concatenado"
                                    arrow
                                    placement='top'
                                >
                                    <RecoveryConvert size={"23"} variant='Bold' color={theme.palette.primary.main} />
                                </Tooltip>
                            </Box>
                        )}
                    </div>
                    {/* <Divider className='mb-2' /> */}
                    <div className='sticky'>
                        <AudioPlayer selectedAgent={selectedAgent} src={audioSrc} loading={loadingAudioSrc} handleTimeUpdate={handleTimeUpdate} togglePlayPause={togglePlayPause} isPlaying={isPlaying} setIsPlaying={setIsPlaying} audioRef={audioRef} manualChangeTime={manualChangeTime} />
                    </div>
                    <div className={`flex-grow overflow-auto mb-2 h-[945px] ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                        {/*<ChatComponent messages={audioData?.decode_transcription?.paragraphs} data={audioData} selectedAgent={selectedAgent} selectedAudio={selectedAudio} loadingAudioData={loadingAudioData} currentTime={currentTime} handleManualChangeTime={handleManualChangeTime} macTime={macTime} priceTime={priceTime} setMacTime={setMacTime} setPriceTime={setPriceTime}/>*/}
                        <ChatComponent messages={messages} data={audioData} selectedAgent={selectedAgent} selectedAudio={selectedAudio} loadingAudioData={loadingAudioData} currentTime={currentTime} handleManualChangeTime={handleManualChangeTime} macTime={macTime} priceTime={priceTime} setMacTime={setMacTime} setPriceTime={setPriceTime}/>
                    </div>
                </div>
                {audioSrc && <FloatingAudioControl togglePlayPause={togglePlayPause} isPlaying={isPlaying} />}
            </Grid>

            <Grid item xs={12} md={3.1} lg={3.1}>
                <MainCard >
                    <div className='flex flex-col justify-center '>
                        {/* <div className='absolute top-1 right-1'>
                            <IconButton color="error" className="text-end">
                                <Add style={{ transform: 'rotate(45deg)' }} />
                            </IconButton>
                        </div> */}
                        <div className='flex flex-col items-center'>
                            <Badge color={selectedAgent && selectedAgent?.audios_count > 0 ? "success" : "warning"} overlap="circular" variant="dot">
                                <Avatar alt="Natacha" type="filled" size="lg" src={selectedAgent?.img || ""}>{selectedAgent?.name ? selectedAgent?.name.substring(0, 1) : "U"}</Avatar>
                            </Badge>
                            <span className={`text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-center mt-1`}>{selectedAgent?.name || "Usuario"}</span>
                            <span className='text-xs text-zinc-400 text-center'>Agente</span>
                        </div>
                        <div className='flex flex-row justify-center items-center mt-2'>
                            {!selectedAgent ? (
                                <>
                                    <TickCircle
                                        size="16"
                                        className='text-zinc-500'
                                        variant="Bold"
                                    />
                                    <Chip className={"bg-[#F6FFED] text-zinc-500"} label="Activo" size="small" variant="filled" />
                                </>
                            ) : (
                                selectedAgent?.audios_count > 0 ? (
                                    <>
                                        <TickCircle
                                            size="16"
                                            className='text-green-500'
                                            variant="Bold"
                                        />
                                        <Chip className={"bg-[#F6FFED] text-green-500"} label="Activo" size="small" variant="filled" />
                                    </>
                                ) : (
                                    <>
                                        <TickCircle
                                            size="16"
                                            className='text-yellow-500'
                                            variant="Bold"
                                        />
                                        <Chip className={"bg-[#F6FFED] text-yellow-500"} label="Sin audios" size="small" variant="filled" />
                                    </>
                                )
                            )}
                        </div>

                        <div className='flex text-sm mt-4 mb-2'>
                            <h1>Estadisticas</h1>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-center items-center ml-4 mt-'>
                            <AgentStatsRadialChart data={chartData} />
                        </div>

                        <div className='flex flex-row justify-between mt-4 mb-2 items-center w-full'>
                            <div className='flex flex-row items-end space-x-4 w-full'>
                                <Button onClick={() => handleManualChangeTime(priceTime)} fullWidth disabled={!priceTime || !isPlaying} className={`rounded-md text-white hover:text-white ${theme.palette.mode === ThemeMode.DARK ? "disabled:text-black" : "disabled:text-white"} ${priceTime && isPlaying ? 'bg-green-500 hover:bg-green-400' :  theme.palette.mode === ThemeMode.DARK ? 'bg-gray-500' : 'bg-gray-300'}`}>
                                    <div className='flex flex-row m-2 space-x-2 items-center w-full'>
                                    <Money4 />
                                    <Typography variant='body1'>Precio</Typography>
                                    </div>
                                </Button>
                                <Button onClick={() => handleManualChangeTime(macTime)} fullWidth disabled={!macTime || !isPlaying} className={`rounded-md text-white hover:text-white ${theme.palette.mode === ThemeMode.DARK ? "disabled:text-black" : "disabled:text-white"} ${macTime && isPlaying ? 'bg-green-500 hover:bg-green-400' :  theme.palette.mode === ThemeMode.DARK ? 'bg-gray-500' : 'bg-gray-300'}`}>
                                <div className='flex flex-row m-2 space-x-2 items-center w-full'>
                                    <Check />
                                    <Typography variant='body1'>MAC</Typography>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <Divider />

                        <Button onClick={() => handleFilterChat('unmissable')} onKeyDown={(e) => handleKeyDown(e, 'unmissable')} fullWidth disabled={!audioData} className={lastFilter === 'unmissable' && `bg-orange-200`}>
                        <div className={`flex flex-row justify-start mt-4 mb-2 w-full ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>
                            <div className='flex flex-row justify-start space-x-2 w-full'>
                                <Avatar alt="Dot" size="xs" type="filled" className='bg-green-500'>&nbsp;</Avatar>
                                <div className='flex flex-col items-start'>
                                    <p className='text-sm font-semibold'>Infaltables</p>
                                    <p className='text-sm text-zinc-400'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{audioData?.unmissable ? `${audioData?.unmissable} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}</p>
                                </div>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-sm font-semibold'>
                                    {!loadingAudioData ? (
                                        <p className='text-sm font-semibold'>{audioData?.unmissable || <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                <p className='text-xs text-zinc-400'>{!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{audioData?.unmissable_percentage || audioData?.unmissable_percentage >= 0 ? `${audioData?.unmissable_percentage?.toFixed(2)}%` : <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}</p>
                            </div>
                        </div>
                        </Button>
                        <Divider />
                        <Button onClick={() => handleFilterChat('forbidden')} onKeyDown={(e) => handleKeyDown(e, 'forbidden')} disabled={!audioData} fullWidth className={lastFilter === 'forbidden' && `bg-orange-200`}>
                        <div className={`flex flex-row justify-start my-2 w-full ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>
                            <div className='flex flex-row justify-start space-x-2 w-full'>
                                <Avatar alt="Dot" size="xs" type="filled" className='bg-[#EA1F63]'>&nbsp;</Avatar>
                                <div className='flex flex-col items-start'>
                                    <p className='text-sm font-semibold'>No permitidas</p>
                                    <p className='text-sm text-zinc-400'>{!loadingAudioData ? (
                                        <p className='text-sm font-semibold'>{audioData?.not_allowed || audioData?.not_allowed >= 0 ? `${audioData?.not_allowed} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                </div>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-sm font-semibold'>{!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{audioData?.not_allowed || audioData?.not_allowed >= 0 ? `${audioData?.not_allowed}%` : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}</p>
                                <p className='text-xs text-zinc-400'>{!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{audioData?.not_allowed_percentage || audioData?.not_allowed_percentage >= 0 ? `${audioData?.not_allowed_percentage?.toFixed(2)}%` : <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}</p>
                            </div>
                        </div>
                        </Button>
                        <Divider />
                        {/*<div className='flex flex-row justify-between my-2 items-center'>*/}
                        <Button fullWidth disabled={true}>
                        <div className={`flex flex-row justify-start my-2 w-full ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>
                            <div className='flex items-center space-x-2 w-full'>
                                <Avatar alt="Dot" size="xs" type="filled" className='bg-[#1890FF]'>&nbsp;</Avatar>
                                <div className='flex flex-col text-start'>
                                    <p className='text-sm font-semibold'>Puntaje de la llamada</p>
                                    <p className='text-sm text-zinc-400'>
                                        {!loadingAudioData ? (
                                            // <p className='text-sm font-semibold'>{audioData?.general_score ? `${audioData?.general_score} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                            <p className='text-sm font-semibold'>{audioData?.general_score ? `` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-sm font-semibold'>
                                    {!loadingAudioData ? (
                                        <p className='text-sm font-semibold'>{audioData?.general_score ? `${audioData?.general_score}` : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </p>
                                <p className='text-xs text-zinc-400'>
                                    {!loadingAudioData ? (
                                        // <p className='text-sm font-semibold'>{audioData?.sales_arguments ? `${audioData?.sales_arguments?.toFixed(2)}%` : <Skeleton variant="rounded" width={35} />}</p>
                                        <p className='text-sm font-semibold'>{audioData?.sales_arguments ? `` : <Skeleton variant="rounded" width={35} />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </p>
                            </div>
                        </div>
                        </Button>
                        <Divider />
                        {/*<div className='flex flex-row justify-between mt-2 mb-4 items-center'>*/}
                        <Button fullWidth disabled={true}>
                        <div className={`flex flex-row justify-start my-2 w-full ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>
                            <div className='flex items-center space-x-2 w-full'>
                                <Avatar alt="Dot" size="xs" type="filled" className='bg-[#FEBF20]'>&nbsp;</Avatar>
                                <div className='flex flex-col items-start text-start'>
                                    <p className='text-sm font-semibold'>Aceptación de compra</p>
                                    <p className='text-sm font-semibold text-zinc-400'>Detección de precio</p>
                                    {/* <p className='text-sm text-zinc-400'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{audioData?.sales_acceptance_percentage ? `${audioData?.sales_acceptance_percentage?.toFixed(1)}% Match` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}
                                    </p> */}
                                </div>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='text-sm font-semibold'>
                                    {!loadingAudioData ? (
                                        <p className='text-sm font-semibold'>{audioData?.purchase_acceptance ? `${audioData?.purchase_acceptance?.toFixed(1)}%` : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                <p className='text-xs text-zinc-400'>
                                    {!loadingAudioData ? (
                                        <p className='text-sm font-semibold'>{audioData?.sales_acceptance_percentage ? `${audioData?.sales_acceptance_percentage?.toFixed(1)}%` : <Skeleton variant="rounded" width={35} />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </p>
                            </div>
                        </div>
                        </Button>
                    </div>
                </MainCard>
                <MainCard className="mt-2">
                    <div className='flex flex-col justify-center '>
                        <div className='flex flex-row'>
                            <span className='text-base mr-1 text-center'>KPIS</span>
                            <span className='text-base text-center font-bold'>Campaña</span>
                        </div>
                    </div>
                    <div className='flex flex-col mt-2'>
                        <div className='flex flex-row items-center mb-3'>
                            {/* <Badge color="success" overlap="circular" variant="dot"> */}
                            <Avatar alt="Natacha" type="filled" size="md" src={selectedData?.campaign?.logo || ""} >{selectedData?.campaign?.name?.substring(0, 1) || ""}</Avatar>
                            {/* </Badge> */}
                            <div className='flex flex-col ml-2'>
                                <span className={`text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-start mt-1`}>{selectedData?.sponsor?.name || ""}</span>
                                <span className='text-sm text-orange-500 text-start'>{selectedData?.campaign?.name || ""}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between mt-4 mb-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Puntaje promedio</p>
                            </div>
                            <div className='flex flex-col'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{kpi?.campaign?.campaign_base?.score?.toFixed(1) || <Skeleton variant="rounded" width={35} />}{kpi?.campaign?.campaign_base?.score && "%"}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Aceptación de compra (MAC)</p>
                            </div>
                            <div className='flex flex-col'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{kpi?.total_buy_accepted?.toFixed(1) || <Skeleton variant="rounded" width={35} />}{kpi?.total_buy_accepted?.toFixed(1) && "%"}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Score general</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{audioData?.general_score || <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Audios analizados</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{kpi?.total_archives_process || <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Audios afectados</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{kpi?.total_calls_affected || <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between mt-3 mb-4'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>TMO</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{kpi?.tmo?.toFixed(1) || <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                    </div>
                </MainCard>


                {/* </Grid> */}
            </Grid>
            <AudioSelectorModal
                open={modalOpen}
                onClose={handleCloseModal}
                audios={audios}
                setSelectedAudio={setSelectedAudio}
                selectedAudio={selectedAudio}
            />
        </Grid>
    );
}

export default VapIndex