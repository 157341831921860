import { AvatarGroup, Divider, Typography, Chip, LinearProgress, Skeleton } from '@mui/material';
import { ThemeMode } from 'config';
import { useTheme } from '@mui/material/styles';
import Avatar from 'components/@extended/Avatar';
import { sponsors } from 'data/sponsors';
import { ArrowDown, ArrowDown3, ArrowRight3, ArrowUp, Calendar, CloudChange, Element } from 'iconsax-react';
import MainCard from 'components/MainCard';
import CustomTooltip from 'components/@extended/Tooltip';
import { useNavigate } from 'react-router-dom';
import { width } from '@mui/system';

const getColorProps = (serverStatus) => {
    switch (serverStatus) {
        case null:
        case undefined:
            return { background: '#6610F221', barColor: '#6610F221' };
        case 'PROCESS':
            return { background: '#565CF8', barColor: '#6A82FB' };
        case 'UPLOADING':
            return { background: '#FEBF20', barColor: '#FFD700' };
        case 'FINISHED':
            return { background: '#06BF7B', barColor: '#00FA9A' };
        case 'ACTIVE':
        case 'ACTIVO':
            return { background: '#06BF7B21', barColor: 'rgb(22 163 74)' };
        case 'INACTIVE':
        case 'INACTIVO':
            return { background: '#FEBF20', barColor: '#FFD700' };
        default:
            return { background: '#9399AA', barColor: '#B3B6B7' };
    }
}

const getStatusProps = (serverStatus) => {
    switch (serverStatus) {
        case null || undefined:
            return { label: "Sin audios", className: "bg-[#EA1F6321] text-pink-800" };
        case 'PROCESS':
            return { label: "Procesando", className: "bg-[#565CF821] text-blue-800" };
        case 'UPLOADING':
            return { label: "Cargando", className: "bg-[#FEBF20] text-yellow-800" };
        case 'FINISHED':
            return { label: "Completado", className: "bg-[#06BF7B21] text-green-500" };
        case 'ACTIVE':
            return { label: "Activo", className: "bg-[#06BF7B21] text-green-500" };
        case 'INACTIVE':
            return { label: "Activo", className: "bg-[#FEBF20] text-yellow-800" };
        case 'ACTIVO':
            return { label: "Activo", className: "bg-[#06BF7B21] text-green-500" };
        case 'INACTIVO':
            return { label: "Activo", className: "bg-[#FEBF20] text-yellow-800" };
        default:
            return { label: serverStatus, className: "bg-[#9399AA21] text-slate-800" };
    }
}

const VDivider = () => {
    return (
        <div className="w-6 border-r-2 border-slate-300 mr-5">&nbsp;</div>
    )
}

const Members = ({ data }) => {
    return (
        <div className='flex flex-col items-start'>
            <AvatarGroup max={4} size="sm">
                {data.map(user => (
                    <Avatar key={user.id} alt={user.name} src={user.avatar} />
                ))}
            </AvatarGroup>
        </div>
    );
}

const SponsorComponent = ({ name, logoUrl, minutes, investment, progress, to, assigned, status, loading, id, percentageMinutesProcess, tmo, percentageTmo, totalCallsAffected, totalBuyAcepted, callQuality, mac, kpiSponsor, unreadCalls }) => {
    const theme = useTheme();
    const { label, className } = getStatusProps(status);
    const { background, barColor } = getColorProps(status);
    const navigate = useNavigate();
    const handleCampaignClick = (campaign) => {
        navigate(`/vap/all-campaign/${campaign}`);
    }

    return (
        <MainCard className={`shadow-md  hover:cursor-pointer`} onClick={() => { handleCampaignClick(id) }} sx={{ width: 300 }}>
            <div className='flex flex-row items-center justify-between space-x-1'>
                <div className='flex items-center'>
                    <div className='items-center mr-1'>
                        {loading ? (
                            <Skeleton variant="circular" width={40} height={40} />
                        ) : (
                            <Avatar src={logoUrl} alt={name} size="sm" />
                        )}

                    </div>
                    <div >
                        <Typography variant="body1" className='ml-1 text-sm font-bold'>{loading ? <Skeleton width={60} /> : name}</Typography>
                    </div>
                </div>
                <div className=''>
                    {/* <Status value={status} /> */}
                    {loading ? (
                        <Skeleton width={70} height={20} variant="rounded" className='rounded-full' />
                    ) : (
                        <Chip label={label} className={className} size="small" />
                    )}
                </div>
            </div>
            <div className='flex justify-between pt-4'>
                <div className='flex flex-col justify-center'>
                    <div className='flex flex-row justify-center items-center'>
                        <Typography className="font-semibold text-sm" >Llamadas: </Typography>
                        {loading ? (
                            <Skeleton width={50} height={20} variant="rounded" className='rounded-full' />
                        ) : (
                            <Typography className="font-bold text-sm ml-2">{to || 0}</Typography>
                        )}
                    </div>
                    <div className='flex flex-row items-start justify-start text-start '>
                        <div className={`text-start ${percentageMinutesProcess > 0 ? "text-green-500" : "text-red-500"}`}>
                            {loading ? (
                                <Skeleton width={20} height={15} variant="rounded" className='rounded-full' />
                            ) : (
                                <Typography className='text-sm'>{percentageMinutesProcess?.toFixed(1)}%</Typography>
                            )}
                        </div>
                        <div className={`${percentageMinutesProcess > 0 ? "text-green-500" : "text-red-500"} text-sm`}>
                            {loading ? (
                                <Skeleton width={19} height={16} variant="rounded" className='rounded-full' />
                            ) : (
                                percentageMinutesProcess > 0 ? (
                                    <ArrowUp size={19} style={{ transform: 'rotate(45deg)' }} />
                                ) : (
                                    <ArrowDown size={19} style={{ transform: 'rotate(45deg)' }} />
                                )
                            )}
                        </div>
                    </div>
                </div>
                <VDivider />
                <div className='flex flex-col justify-center'>
                    <div className='flex flex-row justify-center items-center'>
                        <Typography className="font-bold text-sm">TMO:</Typography>
                        {loading ? (
                            <Skeleton width={50} height={20} variant="rounded" className='rounded-full' />
                        ) : (
                            <><span className="font-bold text-sm ml-2" >{Math.trunc(tmo) || 0}</span><span className="text-sm">min</span></>
                        )}
                    </div>

                    <div className='flex flex-row items-start justify-start text-start '>
                        <div className={`text-start ${percentageTmo > 0 ? "text-green-500" : "text-red-500"}`}>
                            {loading ? (
                                <Skeleton width={20} height={15} variant="rounded" className='rounded-full' />
                            ) : (
                                <Typography className='text-sm'>{percentageTmo?.toFixed(1) || 0}%</Typography>
                            )}
                        </div>
                        <div className={`${percentageTmo > 0 ? "text-green-500" : "text-red-500"} text-sm`}>
                            {loading ? (
                                <Skeleton width={19} height={16} variant="rounded" className='rounded-full' />
                            ) : (
                                percentageTmo > 0 ? (
                                    <ArrowUp size={19} style={{ transform: 'rotate(45deg)' }} />
                                ) : (
                                    <ArrowDown size={19} style={{ transform: 'rotate(45deg)' }} />
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-2'>
                <div className='flex py-2'>
                    <div className="w-40">
                        <Typography className="text-left text-sm font-semibold">Progreso</Typography>
                    </div>
                    <div className="w-40">
                        {!loading && (
                            <Typography className="text-right font-semibold" variant="body1">{parseFloat(progress?.toFixed(1)) || 0}%</Typography>
                        )}
                    </div>
                </div>
                <LinearProgress
                    variant="determinate"
                    value={progress || 0}
                    sx={{
                        height: 8, // Altura del LinearProgress
                        borderRadius: 5,
                        backgroundColor: background, // Color de fondo del track
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: barColor, // Color de la barra
                        }
                    }}
                />
                <div className='flex pt-2'>
                    <div className="w-40">
                        <Typography className="text-left font-semibold" variant="body1">0</Typography>
                    </div>
                    <div className="w-40">
                        {!loading && (
                            <Typography className="text-right font-semibold" variant="body1">{to || 0}</Typography>
                        )}
                    </div>
                </div>
            </div>
            <Divider />
            <div className='flex flex-row pt-3 justify-between'>
                <div className='flex flex-col justify-center text-start space-y-1'>
                    <span className='text-sm text-start'>Ventas</span>
                    <span className='text-sm text-start'>Afectadas</span>
                    <span className='text-sm text-start'>MAC</span>
                    <span className='text-sm text-start'>No Leídas</span>
                </div>

                <div className='flex flex-col justify-center space-y-1'>
                    {loading ? (
                        <p className='text-sm font-bold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></p>
                    ) : (
                        <p className='text-sm font-bold text-end'>{totalBuyAcepted || 0}</p>
                    )}
                    {loading ? (
                        <p className='text-sm font-bold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></p>
                    ) : (
                        <p className='text-sm font-bold text-end'>{totalCallsAffected || 0}</p>
                    )}
                    {loading ? (
                        <p className='text-sm font-bold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></p>
                    ) : (
                        <p className='text-sm font-bold text-end'>{`${mac ? `${mac?.toFixed(1)}%` : 0}`}</p>
                    )}
                    {loading ? (
                        <p className='text-sm font-bold'><Skeleton variant="rounded" className='rounded-full mt-[2px]' width={25} /></p>
                    ) : (
                        <p className='text-sm font-bold text-end'>{unreadCalls ? unreadCalls : 0}</p>
                    )}
                </div>
            </div>
        </MainCard>
    );
}

export default SponsorComponent;