import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project-imports
import Drawer from './Drawer';
import VapDrawer from './VapDrawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import { DRAWER_WIDTH } from 'config';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { MenuOrientation } from 'config';
import useAuth from 'hooks/useAuth';
import useDataStore from 'store/useDataStore';
import useAuthStore from 'store/useAuthStore';
import CustomBreadcrumbs from 'components/@extended/CustomBreadcrumbs';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;
  const downXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const { user } = useAuthStore();
  const { getUserList, getCampaignList, getSponsorList, getAgentList } = useAuth();
  const { setUsers, setCampaign, setSponsor, setAgents } = useDataStore();

  // useEffect(() => {
  //   const fetchUsers = () => {
  //     getUserList()
  //       .then((response) => {
  //         setUsers(response.data.data)
  //       }).catch((error) => {
  //         console.error(error)
  //       }).finally(() => {
  //         //setLoadingData(false)
  //       })
  //   }

  //   const fetchCampaign = () => {
  //     getCampaignList()
  //       .then((response) => {
  //         setCampaign(response.data.data)
  //       }).catch((error) => {
  //         console.error(error)
  //       }).finally(() => {
  //         //setLoadingData(false)
  //       })
  //   }

  //   const fetchSponsor = () => {
  //     getSponsorList()
  //       .then((response) => {
  //         setSponsor(response.data.data)
  //       }).catch((error) => {
  //         console.error(error)
  //       }).finally(() => {
  //         //setLoadingData(false)
  //       })
  //   }


  //   const fetchAgents = () => {
  //     getAgentList()
  //       .then((response) => {
  //         setAgents(response.data.data)
  //       }).catch((error) => {
  //         console.error(error)
  //       }).finally(() => {
  //         //setLoadingData(false)
  //       })
  //   }

  //   fetchUsers()
  //   fetchCampaign(user?.uuid || "", user?.rol[0] || "")
  //   fetchSponsor(user?.uuid || "", user?.rol[0] || "")
  //   fetchAgents()
  // }, [])
  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  const isAtVap = currentPath.includes('/voice-analyzer');

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      {isAtVap ? (
        !isHorizontal ? <VapDrawer /> : <HorizontalBar />
      ) : (
        !isHorizontal ? <Drawer /> : <HorizontalBar />
      )}

      <Box component="main" sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 2, md: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit', mb: isHorizontal ? 2 : 'inherit' }} />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <CustomBreadcrumbs />
          {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
          <Outlet />
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
