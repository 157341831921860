import { Link as RouterLink, useParams } from 'react-router-dom';

// material-ui
import { Avatar, Box, Badge, CardContent, Grid, Link, Stack, Typography, Card, CardHeader, useTheme } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import './style.css';
import CircularWithPath from 'components/@extended/progress/CircularWithPath';
import { formatDateString } from 'utils/aihubUtils';
import { ThemeMode } from 'config';
import aihubLogo from 'assets/images/icons/aihubLogo.png';
import aihubTextLogo from 'assets/images/icons/aihubTextLogo.png';
import IconButton from 'themes/overrides/IconButton';
import { Like1 } from 'iconsax-react';
import useAuthStore from 'store/useAuthStore';

const RecomendationComponent = ({ recomendation, selected, innerRef, theme }) => {
    const { user } = useAuthStore()
    const { likeRecommendation } = useAuth();
    const [liked, setLiked] = useState(false)
    const handleLike = () => {
        setLiked(!liked)
        const data = {
            recommendation_id: recomendation.id
        }
        likeRecommendation(data)
            .then((response) => {
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                //setKpiLoading(false)
            });
    }

    useEffect(() => {
        const userLiked = recomendation.likes.some(like => like.user_id === user.id);
        setLiked(userLiked);
    }, [recomendation.likes, user.id]);

    return (
        <Card className={`p-3 rounded-xl ${selected ? theme.palette.mode === ThemeMode.DARK ? "bg-gray-700" : "bg-gray-100" : theme.palette.mode === ThemeMode.DARK ? "bg-[#1d2630]" : "bg-white"}  ml-4 mt-2 hover:cursor-default`} elevation={0} ref={innerRef} sx={{ border: '1px solid', borderColor: theme.palette.divider, }}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Avatar color="success" type="filled" src={aihubLogo} alt="AI Hub" size="sm" >GPT</Avatar>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="subtitle1" color="primary">
                            {recomendation.title}
                        </Typography>
                        <Typography align="left" variant="caption" className='text-sm'>
                            {recomendation.content}
                        </Typography>
                        <div className='flex justify-end items-center'>
                            <Typography variant="caption" className='font-semibold text-xs'>
                                {formatDateString(recomendation?.date) || recomendation?.date}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <Like1 onClick={handleLike} variant={liked || recomendation.like ? "Bold" : "Outline"} className="hover:cursor-pointer" />
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

const AiHubActivityCard = ({ campaignSelected }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { campaignId } = useParams();
    const [recomendations, setRecomendations] = useState([]);
    const [prevCampaign, setPrevCampaign] = useState(null)
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const loaderRef = useRef(null);
    const { getCampaignRecomendation } = useAuth();
    const [recomendationSelected, setRecomendationSelected] = useState(0)
    const recomendationRefs = useRef([]);
    const contentRef = useRef(null);
    const [isAutoScrollActive, setIsAutoScrollActive] = useState(true);
    const [onError, setOnError] = useState(false)

    useEffect(() => {
        if (prevCampaign?.campaign?.uuid !== campaignSelected?.campaign?.uuid) {
            setPage(1)
            setRecomendations([])
            setHasMore(true);
            setPrevCampaign(campaignSelected)
            setRecomendationSelected(0)
        }
        if (campaignSelected?.campaign && campaignId) {
            fetchCampaignRecomendation(campaignSelected.campaign?.uuid, page);
        }
    }, [campaignSelected, page]);

    const fetchCampaignRecomendation = (campaignId, page) => {
        if (isLoading || !hasMore || !campaignSelected?.campaign || onError) return;
        setPage(page)
        setIsLoading(true);
        getCampaignRecomendation(campaignId, page)
            .then((response) => {
                const newRecomendations = response.data.data.data;
                setRecomendations(prev => [...prev, ...newRecomendations]);
                setHasMore(response.data.data.next_page_url != null);
            })
            .catch((error) => {
                console.error(error);
                setOnError(true)
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !isLoading) {
                    fetchCampaignRecomendation(campaignSelected.campaign?.uuid, recomendations.length / 2 + 1);
                }
            },
            { threshold: 0.1 }
        );

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loaderRef, hasMore, isLoading, campaignSelected, recomendations.length]);

    useEffect(() => {
        recomendationRefs.current = recomendationRefs.current.slice(0, recomendations.length);
    }, [recomendations.length]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isAutoScrollActive) {
                setRecomendationSelected(prev => {
                    const nextIndex = (prev + 1) % recomendations.length;
                    const element = recomendationRefs.current[nextIndex];

                    if (element) {
                        const cardContent = contentRef.current;
                        const elementRect = element.getBoundingClientRect();
                        const cardRect = cardContent.getBoundingClientRect();

                        const newScrollTop = element.offsetTop + elementRect.height / 2 - cardRect.height / 2;

                        cardContent.scrollTop = newScrollTop;
                    }

                    return nextIndex;
                });
            }
        }, 30000); //30 SEGUNDOS

        return () => clearInterval(interval);
    }, [recomendations.length, isAutoScrollActive, campaignSelected]);

    return (
        <MainCard title="Recomendaciones" content={false} chip="Ai Hub" headerCustom>
            <CardContent
                ref={contentRef}
                className={`overflow-y-auto h-[252px] ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}
                onMouseEnter={() => setIsAutoScrollActive(false)}
                onMouseLeave={() => setIsAutoScrollActive(true)}
                sx={{
                    scrollBehavior: 'smooth'
                }}
            >
                <Grid container spacing={3} alignItems="center" >
                    {recomendations.length > 0 ? (
                        recomendations.map((recomendation, index) => (
                            <RecomendationComponent key={recomendation.id} recomendation={recomendation} hasMore={hasMore} loaderRef={loaderRef} selected={recomendationSelected === index} innerRef={el => recomendationRefs.current[index] = el} theme={theme} />
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <div className='flex flex-row justify-center items-center py-2'>
                                <p>No hay recomendaciones actualmente</p>
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {(hasMore && !onError) && (
                            <div ref={loaderRef} className='flex flex-col text-center justify-center items-center'>
                                <CircularWithPath />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
};

export default AiHubActivityCard