export const global = [
    {
        id: 12,
        name: "América Latina",
        data: [
            {
                name: "Colombia",
                value: 20
            },
            {
                name: "Argentina",
                value: 5
            },
            {
                name: "Chile",
                value: 15
            },
            {
                name: "Brazil",
                value: 35
            }
        ]
    },
    {
        id: 17,
        name: "Estatos Unidos",
        data: [
            {
                name: "Colombia",
                value: 20
            },
            {
                name: "Argentina",
                value: 5
            },
            {
                name: "Chile",
                value: 15
            },
            {
                name: "Brazil",
                value: 35
            }
        ]
    },
    {
        id: 23,
        name: "Centro América",
        data: [
            {
                name: "Colombia",
                value: 20
            },
            {
                name: "Argentina",
                value: 5
            },
            {
                name: "Chile",
                value: 15
            },
            {
                name: "Brazil",
                value: 35
            }
        ]
    },
    {
        id: 24,
        name: "Norte América",
        data: [
            {
                name: "Colombia",
                value: 20
            },
            {
                name: "Argentina",
                value: 5
            },
            {
                name: "Chile",
                value: 15
            },
            {
                name: "Brazil",
                value: 35
            }
        ]
    },
    {
        id: 25,
        name: "Europa",
        data: [
            {
                name: "Colombia",
                value: 20
            },
            {
                name: "Argentina",
                value: 5
            },
            {
                name: "Chile",
                value: 15
            },
            {
                name: "Brazil",
                value: 35
            }
        ]
    }
]