import { Avatar, Typography, useTheme } from "@mui/material";
import { ThemeMode } from 'config';
import { forwardRef } from 'react';

const keywordColors = {
    keywords_good: 'text-yellow-500',
    unmissable: 'text-green-500',
    forbidden: 'text-red-400'
};

const highlightKeywords = (text, keywords) => {
    if (!keywords) return text;

    const allKeywords = Object.values(keywords).flat();
    if (!Array.isArray(allKeywords) || allKeywords.length === 0) return text;

    const parts = text.split(new RegExp(`(${allKeywords.join('|')})`, 'gi'));
    return parts.map((part, index) => {
        const keywordType = Object.keys(keywords).find(type => keywords[type].includes(part.toLowerCase()));
        return keywordType ? <span key={index} className={keywordColors[keywordType]}>{part}</span> : part;
    });
};

const MessageComponent = ({reference, paragraph, sentence, customerImg, selectedAgent, isActive, keywords, handleManualChangeTime, handleKeyDown}) => {
    const theme = useTheme();
    return (
        <div ref={reference} 
            className={`flex items-center space-x-2 space-y-2 ${paragraph.speaker === 0 ? 'justify-start' : 'justify-end'} mr-2`}
            onClick={() => handleManualChangeTime(sentence.start)}
            onKeyDown={(e) => handleKeyDown(e, sentence.start)}
            role="button"
            tabIndex="0"
            aria-label="Select sentence">
            {paragraph.speaker === 0 && <Avatar alt={selectedAgent?.name || "User image"} type="filled" size="sm" src={selectedAgent?.img || ""}>{selectedAgent?.name.substring(0, 1) || "U"}</Avatar>}
            <div className={`flex flex-col items-start hover:cursor-pointer`}>
                <Typography variant='body1' className={`border p-2 rounded-lg ${isActive && paragraph.speaker === 0 ? `${theme.palette.mode === ThemeMode.DARK ? "bg-blue-500/[.6] font-semibold" : "bg-blue-100"} rounded` : isActive && paragraph.speaker === 1 ? `${theme.palette.mode === ThemeMode.DARK ? "bg-orange-500/[.9] font-semibold" : "bg-orange-100"} rounded` : `${theme.palette.mode === ThemeMode.DARK ? "bg-orange-500/[.3] border-slate-800 text-white" : "bg-white border-[#E1E1E1]"} text-black`} ${sentence?.mac && "border-yellow-500"} ${sentence?.price && "border-yellow-500"}`}>
                    {highlightKeywords(sentence?.text, keywords)}
                </Typography>
                <Typography variant='caption' className='text-[#8C8C8C]'>
                    {new Date(sentence.start * 1000).toISOString().substr(14, 5)} - {new Date(sentence.end * 1000).toISOString().substr(14, 5)} {sentence?.mac && <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold`}> - Mac</span>} {sentence?.price && <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold`}> - Precio</span>}
                </Typography>
            </div>
            {paragraph.speaker === 1 && <Avatar src={customerImg} alt="MD" className="w-10" >MD</Avatar>}
        </div>
    );
}

export default forwardRef(MessageComponent);