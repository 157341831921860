import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';

// project import
import { ThemeMode } from 'config';

// chart options
const redialBarChartOptions = {
    chart: {
        type: 'radialBar',
        width: 50,
        height: 50
    },
    plotOptions: {
        radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
                margin: 0,
                size: '30%',
                background: 'transparent',
                image: undefined
            },
            dataLabels: {
                name: {
                    show: false
                },
                value: {
                    show: false
                }
            }
        }
    },
    labels: ['Venta', 'No permitidas', 'Infaltables', 'Compra'],
    legend: {
        show: true,
        floating: true,
        fontSize: '13px',
        position: 'left',
        offsetX: -5,
        offsetY: -5,
        labels: {
            useSeriesColors: true
        },
        markers: {
            size: 5
        },
        formatter(seriesName, opts) {
            return `${seriesName}`;
        },//:  ${opts.w.globals.series[opts.seriesIndex]}

    },

};

const AgentStatsRadialChart = ({ data }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    const { primary } = theme.palette.text;
    const line = theme.palette.divider;
    const grey200 = theme.palette.secondary[200];
    const [series, setSeries] = useState(data)
    const [options, setOptions] = useState(redialBarChartOptions);

    const secondary = "#1890FF";
    const primaryMain = "#EA1F63";
    const successDark = "#00C795";
    const error = "#FEBF20";

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [secondary, primaryMain, successDark, error],
            xaxis: {
                labels: {
                    style: {
                        colors: [primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            grid: {
                borderColor: line
            },
            plotOptions: {
                radialBar: {
                    track: {
                        background: line
                    }
                }
            },
            theme: {
                mode: mode === ThemeMode.DARK ? 'dark' : 'light'
            }
        }));
    }, [mode, primary, line, grey200, secondary, primaryMain, successDark, error, data]);

    useEffect(() => {
        if (data) {
            setSeries(data);
        } else {
            setSeries([0, 0, 0, 0]);
        }
    }, [data]);

    return (
        <Box id="chart" sx={{ bgcolor: 'transparent' }}>
            <ReactApexChart options={options} series={series} type="radialBar" width={280} height={220} />
        </Box>
    );
}

export default AgentStatsRadialChart