import { CardMedia, LinearProgress, useTheme } from '@mui/material';
import React from 'react'
import filesPlaceholder from 'assets/images/icons/filesPlaceholder.svg';
import filesEmptyPlaceholder from 'assets/images/icons/filesEmptyPlaceholder.svg';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ThemeMode } from 'config';
import MessageComponent from './MessageComponent';
const avatarImage = require.context('assets/images/users', true);

const ChatComponent = ({ messages, data, selectedAgent, selectedAudio, loadingAudioData, currentTime, handleManualChangeTime, priceTime, macTime, setMacTime, setPriceTime }) => {
    const theme = useTheme();
    const messageRefs = useRef({});
    const [activeMessageId, setactiveMessageId] = useState(null)
    const [prevMessage, setPrevMessage] = useState("")
    const [customerImg, setCustomerImg] = useState("")

    useEffect(() => {
        setCustomerImg(avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`))
    }, [])

    useEffect(() => {
        let price = null;
        let mac = null;
        messages?.map((paragraph) => {
            paragraph.sentences.map((sentence) => {
                if (sentence.price) {
                    price = sentence.start;
                }
                if (sentence.mac) {
                    mac = sentence.start;
                }
            });
        });
        if(setPriceTime) setPriceTime(price);
        
        if(setMacTime) setMacTime(mac);

    }, [messages]);

    const scrollToMessage = (id) => {
        messageRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const scrollToMessageFix = (activeMessageId) => {
        if (activeMessageId) {
            const element = messageRefs.current[activeMessageId];
            if (element) {
                const container = element.offsetParent;
                const elementRect = element.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                const newScrollTop = element.offsetTop + elementRect.height / 2 - containerRect.height / 2;
                container.scrollTop = newScrollTop;
            }
        }
    };

    useEffect(() => {
        console.log(macTime);
        console.log(currentTime);
        console.log(findActiveMessageId(currentTime));
        setactiveMessageId(findActiveMessageId(currentTime));
        if (activeMessageId !== prevMessage) {
            scrollToMessage(activeMessageId);
            setPrevMessage(activeMessageId);
        }
    }, [currentTime]);

    const handleKeyDown = (event, startTime) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handleManualChangeTime(startTime);
        }
    };

    const findActiveMessageId = (currentTime) => {
        if (!messages) {
            return null;
        }
        for (let i = 0; i < messages.length; i++) {
            for (let j = 0; j < messages[i].sentences.length; j++) {
                const sentence = messages[i].sentences[j];
                if (currentTime >= sentence.start && currentTime <= sentence.end) {
                    return `message-${i}-${j}`;
                }
            }
        }
        return null;
    };

    if (loadingAudioData) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesPlaceholder} sx={{ width: 100 }} />
                <LinearProgress sx={{ height: 5 }} className="w-2/3" />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center mt-2`}>Estamos procesando el audio</p>
                <p className='text-sm text-center'>Se está generando el transcrito del audio, en breve se mostrará la conversación</p>
            </div>
        );
    }

    if (!selectedAgent) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Completar información</p>
                <p className='text-sm text-center'>Al parecer, aún no ha seleccionado el agente.</p>
            </div>
        );
    }

    if (!selectedAudio) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Completar información</p>
                <p className='text-sm text-center'>Al parecer, aún no ha seleccionado el audio.</p>
            </div>
        );
    }

    if (!messages) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Parece que no hay transcripción</p>
                <p className='text-sm text-center'>Al parecer no hay una transcripción en el audio seleccionado, por favor intente con otro audio.</p>
            </div>
        );
    }

    if (!Array.isArray(messages)) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Parece que no hay transcripción</p>
                <p className='text-sm text-center'>Al parecer no hay una transcripción en el audio seleccionado, por favor intente con otro audio.</p>
            </div>
        );
    }

    if (!data) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}  font-semibold text-base text-center`}>Parece que no hay audio</p>
                <p className='text-sm text-center'>Al parecer no hay audios en las fechas seleccionadas, por favor intente con otras fechas.</p>
            </div>
        );
    } else {
        return (
            <div className='flex flex-col w-full'>
                {messages?.map((paragraph, paragraphIndex) => (
                    <React.Fragment key={paragraph.start}>
                        {paragraph.sentences.map((sentence, sentenceIndex) => {
                            const messageId = `message-${paragraphIndex}-${sentenceIndex}`;
                            const isActive = messageId === activeMessageId;

                            const keywords = {
                                keywords_good: sentence.keywords_good || [],
                                unmissable: sentence.unmissable || [],
                                forbidden: sentence.forbidden || []
                            };

                            return (
                                <MessageComponent
                                reference={el => messageRefs.current[messageId] = el}
                                key={sentenceIndex}
                                paragraph={paragraph}
                                sentence={sentence}
                                customerImg={customerImg}
                                selectedAgent={selectedAgent}
                                isActive={isActive}
                                keywords={keywords}
                                handleManualChangeTime={handleManualChangeTime}
                                handleKeyDown={handleKeyDown}
                                />
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

export default ChatComponent;