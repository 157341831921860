import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, useTheme, LinearProgress, useMediaQuery, linearProgressClasses, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { ThemeMode } from 'config';
import { useSortBy, useTable } from 'react-table';
import { cond } from 'lodash';
import { ArrowDown2, ArrowDown3, ArrowUp2, ArrowUp3 } from 'iconsax-react';
import axios from 'axios';
const avatarImage = require.context('assets/images/users', true);


const VapAgents = ({ campaignSelected, handleAgentSelect }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [agents, setAgents] = useState([]);
    const [loading, setLoading] = useState(false)
    const { getAgentStats } = useAuth();
    const [rowSelected, setRowSelected] = useState(0);

    const fetchCampaignTopAgent = () => {
        const source = axios.CancelToken.source();
        setLoading(true);

        getAgentStats(campaignSelected?.campaign?.uuid, { cancelToken: source.token })
            .then((response) => {
                console.log(response.data)
                setAgents(response.data.data);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    // console.log('Request canceled', error.message);
                } else {
                    console.error(error);
                }
            }).finally(() => {
                setLoading(false);
            });

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    };

    useEffect(() => {
        if (campaignSelected?.campaign) {
            const cancelFetch = fetchCampaignTopAgent();
            return cancelFetch;
        }
    }, [campaignSelected]);

    const columns = useMemo(() => [
        { Header: 'Agente', accessor: 'name', id: 'agent_name' },
        {
            Header: 'Grabaciones',
            accessor: row => row.audios?.[0]?.total_audios ?? 0,
            id: 'total_audios',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'Infaltables',
            accessor: row => row.audios?.[0]?.unmissable ?? 0,
            id: 'unmissable',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'Infaltables no encontradadas',
            accessor: row => row.audios?.[0]?.unmissable_not_found ?? 0,
            id: 'unmissable_not_found',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'No permitidas',
            accessor: row => row.audios?.[0]?.not_allowed ?? 0,
            id: 'not_allowed',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'Puntaje del agente',
            accessor: row => row.score?.[0]?.agent_score || row.score?.[0]?.score || 0,
            id: 'agent_score',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'MAC',
            accessor: row => row.score?.[0]?.mac ?? 0,
            id: 'mac',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        },
        {
            Header: 'TMO',
            accessor: row => row.audios?.[0]?.tmo ?? 0,
            id: 'tmo',
            Cell: ({ value }) => (
                <Typography className='text-red-500'>{value} min</Typography>
            )
        },
        {
            Header: 'Llamadas afectadas',
            accessor: row => row.score?.[0]?.affected_audios ?? 0,
            id: 'affected_audios',
            Cell: ({ value }) => (
                <Typography className='text-center'>{value}</Typography>
            )
        }
    ], [theme]);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const renderCell = (column, data, header) => {
        switch (header) {
            case 'Agente': {
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {data.avatar ? (
                            <Avatar alt={data?.name || "Agente"} size="sm" src={data?.avatar} />
                        ) : (
                            <Avatar src={avatarImage(`./avatar-${data?.length % 10 + 1}.png`)} alt={data || "Agente"} className='w-6 h-6 bg-[#E26205]' />
                        )}
                        <Typography variant="subtitle1" className='text-start'>{data || ""}</Typography>
                    </Stack>
                );
            }
            case 'Grabaciones':
            case 'Infaltables':
            case 'Infaltables no encontradadas':
            case 'No permitidas':
            case 'Puntaje del agente':
            case 'MAC':
            case 'TMO':
            case 'Llamadas afectadas': {
                return (
                    <Typography className={`text-center`}>
                        {data || 0} {header === 'TMO' ? 'min' : ''}
                    </Typography>
                );
            }
            default: {
                return (
                    <Typography variant="subtitle1"></Typography>
                );
            }
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        setSortBy
    } = useTable(
        {
            columns,
            data: agents,
            initialState: {
                sortBy: [
                    {
                        id: 'total_audios',
                        desc: true
                    }
                ]
            }
        },
        useSortBy
    );

    return (
        <TableContainer component={Paper} className={`shadow-md overflow-y-auto ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"} h-full`}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" {...getTableProps()}>
                <TableHead sx={{ position: 'sticky', top: -1, zIndex: 10 }}>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map(column => (
                                <TableCell
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    key={column.id}
                                    align="center"
                                    className="font-bold text-center"
                                >
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? (<div className='flex flex-row justify-center items-center'>
                                                {column?.render('Header')}
                                                <ArrowDown2 size="15" className='ml-1 text-orange-500' />
                                            </div>
                                            )
                                            : (
                                                <div className='flex flex-row justify-center items-center'>
                                                    {column?.render('Header')}
                                                    <ArrowUp2 size="15" className='ml-1 text-orange-500' />
                                                </div>
                                            )
                                        : (
                                            <> {column?.render('Header')}</>
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={`${rowIndex === rowSelected && (theme.palette.mode === ThemeMode.DARK ? "bg-[#131920]" : "bg-slate-200")} hover:cursor-pointer duration-150`}>
                                {row.cells.map((cell, colIndex) => (
                                    <TableCell key={cell.id} align="center" onClick={() => {
                                        handleAgentSelect(row.original);
                                        setRowSelected(rowIndex);
                                    }}>
                                        {renderCell(cell?.column.id, cell?.value, cell?.column.Header)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {loading &&
                <p className='text-center my-8'>
                    <BorderLinearProgress variant="indeterminate" value={50} />
                </p>}
            {agents.length <= 0 && !loading && (
                <p className='text-center my-8'>
                    No hay agentes disponibles para esta campaña.
                </p>
            )}
        </TableContainer>
    );
};

export default VapAgents;