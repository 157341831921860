import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import UserList from 'pages/users/UserList';
import SponsorList from 'pages/sponsor/SponsorList';
import CampaignList from 'pages/campaigns/CampaignList';
import AllSponsor from 'pages/sponsor/AllSponsor';
import AllCampaign from 'pages/campaigns/AllCampaign';
import AgentList from 'pages/agents/AgentList';
import VapIndex from 'pages/vap/VapIndex';
import GlobalSponsor from 'pages/global/GlobalSponsor';
import EGroupCards from 'pages/egroups/EGroupCards';
import VapStatAgents from 'pages/vap/VapStatAgents';
import CampaignStats from 'pages/campaigns/CampaignStats';
import CallList from 'pages/calls/CallList';

// ==============================|| MAIN ROUTES ||============================== //

const NoAdminRoutes = {
  path: '/vap',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    { path: '', element: <AllSponsor /> },
    { path: 'all-sponsors', element: <AllSponsor /> },
    {
      path: 'sponsors',
      children: [
        {
          path: '',
          element: <SponsorList />
        },
        {
          path: 'global',
          element: <GlobalSponsor />
        }
      ]
    },
    {
      path: '',
      children: [
        {
          path: '',
          element: <AllSponsor />
        },
        {
          path: ':sponsorId',
          element: <AllSponsor />
        }
      ]
    },
    {
      path: 'campaigns',
      children: [
        {
          path: 'list',
          element: <CampaignList />
        },
        {
          path: 'calls',
          children: [
            {
              path: '',
              element: <CallList />
            },
            {
              path: ':campaignId',
              element: <CallList />
            }
          ]
        }
      ]
    },
    {
      path: 'egroups',
      children: [
        {
          path: 'list',
          element: <EGroupCards />
        }
      ]
    },
    {
      path: 'all-campaign',
      children: [
        {
          path: '',
          element: <AllCampaign />
        },
        {
          path: ':campaignId',
          element: <AllCampaign />
        },
        {
          path: 'stats/:campaignId',
          element: <CampaignStats />
        }
      ]
    },
    {
      path: 'agents',
      children: [
        {
          path: 'list',
          element: <AgentList />
        },
        {
          path: 'stats/:sponsorId/:campaignId',
          element: <VapStatAgents />
        }
      ]
    },
    {
      path: 'voice-analyzer',
      children: [
        {
          path: '',
          element: <VapIndex />
        },
        {
          path: ':campaignId',
          element: <VapIndex />
        },
        {
          path: ':campaignId/:agentId',
          element: <VapIndex />
        }
      ]
    },
  ]
};

export default NoAdminRoutes;
