import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack
} from '@mui/material';

// third-party
import _ from 'lodash';

import { ThemeMode } from 'config';

// assets
import { Add, Camera, SearchNormal, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import VapIndex from 'pages/vap/VapIndex';
import ChatComponent from 'pages/vap/ChatComponent';
import AudioPlayer from 'pages/vap/AudioPlayer';
import FloatingAudioControl from 'pages/vap/FloatingAudioControl';

const avatarImage = require.context('assets/images/users', true);

const PlayAudio = ({ customer, onCancel, selectedAudio, audioRef, isPlaying, setIsPlaying }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { getCountries, getEgroupsList, getAudioFile, getAudioData } = useAuth();
    // console.log(customer)
    const [avatar, setAvatar] = useState();
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [audioSrc, setAudioSrc] = useState(null);
    const [loadingAudioSrc, setLoadingAudioSrc] = useState(false);
    const [manualChangeTime, setManualChangeTime] = useState(null);
    const [audioData, setAudioData] = useState(null);
    const [loadingAudioData, setLoadingAudioData] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        const fetchAudioData = () => {
            setLoadingAudioData(true)
            // console.log(selectedAudio?.id)
            getAudioData(selectedAudio?.id)
                .then((response) => {
                    // console.log(response.data.data)
                    // setSelectedAudio(null)
                    // try {
                    //     const percentageKeys = ['unmissable_percentage', 'not_allowed_percentage', 'sales_arguments_percentage', 'sales_acceptance'];
                    //     const extractedData = percentageKeys.map(key => response.data.data[key] || 0);
                    //     setChartData(extractedData)
                    // } catch (error) {
                    //     console.error(error)
                    //     setChartData([0, 0, 0, 0])
                    // }
                    // console.log(response.data.data)
                    setAudioData(response.data.data)

                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAudioData(false)
                })
        }

        const fetchAudioFile = () => {
            // console.log(selectedAudio?.id)
            setLoadingAudioSrc(true)
            getAudioFile(selectedAudio?.id)
                .then((response) => {
                    // console.log(response.data.data)
                    setAudioSrc(response.data.data)
                    // setSelectedAudio(null)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAudioSrc(false)
                })
        }

        if (selectedAudio) {
            fetchAudioData()
            fetchAudioFile()
        }
        // console.log(selectedAudio)
    }, [selectedAudio])

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const capitalizeName = (name) => {
        if (!name) return name;
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    const handleManualChangeTime = (value) => {
        // console.log(value);
        setManualChangeTime(value);
    }

    const handleTimeUpdate = (newTime) => {
        setCurrentTime(newTime);
    };

    const handleCloseAudioPlay = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
        onCancel()
    }

    // useEffect(() => {
    //     const fetchCountries = () => {
    //         getCountries()
    //             .then((response) => {
    //                 // console.log(response)
    //                 setCountries(response.data.data)
    //             }).catch((error) => {
    //                 console.error(error)
    //             }).finally(() => {

    //             })
    //     }

    //     const fetchEgroups = () => {
    //         getEgroupsList()
    //             .then((response) => {
    //                 // console.log(response)
    //                 setEgroups(response.data.data)
    //             }).catch((error) => {
    //                 console.error(error)
    //             }).finally(() => {

    //             })
    //     }

    //     if (open) {
    //         fetchCountries()
    //         fetchEgroups()
    //     }
    // }, [open])

    useEffect(() => {
        if (customer?.logo) {
            setAvatar(customer.logo);
        }
    }, [customer?.logo]);

    return (
        <>
            <DialogTitle>Reproducir audio</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }} className={`overflow-auto ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                <div>
                    <div className={`sticky top-1 z-10 py-4 ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white border-zinc-400'}`}>
                        <AudioPlayer
                            selectedAgent={selectedAgent}
                            src={audioSrc}
                            loading={loadingAudioSrc}
                            handleTimeUpdate={handleTimeUpdate}
                            togglePlayPause={togglePlayPause}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                            audioRef={audioRef}
                            manualChangeTime={manualChangeTime}
                        />
                    </div>
                    <div className={`flex-grow overflow-auto mb-2 h-content ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                        <ChatComponent
                            messages={audioData?.decode_transcription?.paragraphs}
                            data={audioData}
                            selectedAgent={{ name: "IGS User" }}
                            selectedAudio={selectedAudio}
                            loadingAudioData={loadingAudioData}
                            currentTime={currentTime}
                            handleManualChangeTime={handleManualChangeTime}
                        />
                    </div>
                    {audioSrc && <FloatingAudioControl togglePlayPause={togglePlayPause} isPlaying={isPlaying} />}
                </div>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
                <Grid container justifyContent="right" alignItems="center">
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {/*<Button color="error" onClick={handleCancel}>
                                Cancelar
                            </Button>*/}
                            <Button variant="contained" className="bg-orange-500" onClick={handleCloseAudioPlay}>
                                Cerrar
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default PlayAudio;