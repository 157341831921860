import { Typography, Grid, Avatar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import igs from 'assets/images/home/igs.png';
import aihub from 'assets/images/home/aihub.png';
import vap from 'assets/images/home/vap.png';
import lead from 'assets/images/home/lead.png';
import labs from 'assets/images/home/labs.png';
import talent from 'assets/images/home/talent.png';
import mam from 'assets/images/home/mam.png';
import tutorial from 'assets/images/home/tutorial.png';
import vbar from 'assets/images/home/vbar.png';
import { ThemeMode } from 'config';
import useAuthStore from 'store/useAuthStore';

const iconMapping = {
    VAP: vap,
    Lead: lead,
    Labs: labs,
    Talent: talent,
    MaM: mam,
    Tutorial: tutorial,
};

const descMapping = {
    VAP: "Voice Analyzer Platform",
    Lead: "Lead Manager",
    Labs: "Training Labs (Pronto)",
    Talent: "IGS Talent Ai (Pronto)",
    MaM: "Meta Agent Multilingual (Pronto)",
    Tutorial: "Real Time Ai (Pronto)",
}

const refMapping = {
    VAP: "/vap",
    Lead: "/lead",
    Labs: "/",
    Talent: "/",
    MaM: "/",
    Tutorial: "/",
};

const HomePage = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const navigate = useNavigate();
    const { userApps } = useAuthStore();

    return (
        <div className='flex flex-col justify-center h-screen px-[15%]'>

            <div className='flex flex-row items-center space-x-4'>
                <img src={igs} alt='IGS logo' className='h-10' />
                <img src={vbar} alt='VBar' className='h-8' />
                <img src={aihub} alt='AIHub logo' className='h-10' />
            </div>

            <div className='space-y-2 pt-14'>
                <Typography className='font-bold text-3xl'>Aplicaciones IGS-AI-HUB</Typography>
                <Typography className='font-normal text-lg text-[#606A82] w-[55%]'>Por favor, selecciona una de nuestras herramientas de análisis, donde encontrarás información valiosa del negocio</Typography>
            </div>
            <div>
                <Grid container xs={12} sm={12} lg={12} className='mt-8' rowSpacing={2} columnSpacing={2}>
                    {userApps?.length > 0 ? (
                        <>
                            {userApps.map((app) => {
                                const Icon = iconMapping[app.name];
                                return (
                                    <Grid item xs={12} sm={4} lg={4} onClick={(e) => {
                                        navigate(refMapping[app.name])
                                        e.stopPropagation()
                                    }
                                    } key={app.id}>
                                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-orange-500 hover:cursor-pointer`}>
                                            <Avatar src={Icon} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' alt={app.name} />
                                            <div className='flex flex-col'>
                                                <Typography className='font-bold text-xl text-orange-500'>{app.name}</Typography>
                                                <Typography className='font-normal text-base'>{descMapping[app.name]}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                );
                            })}
                        </>
                    ) : (
                        <div className='flex flex-col justify-center items-center'>
                            <p className='text-base my-5 mx-4'>No tienes apps asignadas</p>
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    );
}

export default HomePage;