import { useCallback, useEffect, useMemo, useState } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    Button,
    Chip,
    Dialog,
    Grid,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Add, Edit, Eye, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import NewEGroup from './NewEGroup';
import AlertEGroupStatusChange from './AlertEGroupStatusChange';
import AlertEGroupDelete from './AlertEGroupDelete';
import EGroupView from './EGroupView';
import { useSelector } from "react-redux";
import EGroupComponent from './EGroupComponent';
import { openDrawer } from "store/reducers/menu";
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import AddSponsorsToEGroup from './AddSponsorsToEGroup';


const SponsorList = ({ data, loading, setCustomer, handleAdd, setCustomerDeleteId, setSponsorDeleteName, handleAddSponsors, handleClose, handleStatusDialogClose }) => {
    return (
        data.map(egroup => (
            <Grid item xs={12} sm={4} lg={4} key={egroup.id}>
                <EGroupComponent
                    name={egroup.name}
                    logoUrl={egroup.logo}
                    sponsors={egroup.sponsors}
                    country={egroup.country}
                    status={egroup.status}
                    handleEdit={(e) => {
                        e.stopPropagation();
                        setCustomer(egroup);
                        handleAdd(true);
                    }}
                    handleDelete={(e) => {
                        e.stopPropagation();
                        handleClose();
                        setCustomerDeleteId(egroup.id);
                        setSponsorDeleteName(egroup.name);
                    }}
                    handleAdd={(e) => {
                        e.stopPropagation();
                        setCustomer(egroup);
                        setCustomerDeleteId(egroup.id);
                        handleAddSponsors();
                    }}
                    handleSuspend={(e) => {
                        e.stopPropagation();
                        handleStatusDialogClose();
                        setCustomerDeleteId(egroup.id);
                        setSponsorDeleteName(egroup.name);
                    }}
                />
            </Grid>
        ))
    );
}

const EGroupCards = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [loadingData, setLoadingData] = useState(true)
    const [sponsors, setSponsors] = useState([]);
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const [add, setAdd] = useState(false);
    const [modal, setModal] = useState("add");
    const { deleteEgroup, changeStatusEgroup, getEgroupsList } = useAuth();
    const [sponsorDeleteName, setSponsorDeleteName] = useState("")
    const [statusDialogOpen, setStatusDialogOpen] = useState(false)
    const { drawerOpen } = useSelector((state) => state.menu);

    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Grupo económico', path: '/egroups/list' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const addSponsor = (sponsor) => {
        setSponsors([...sponsors, sponsor])
    }

    const handleAddSponsors = () => {
        setAdd(!add);
        setModal("sponsors")
        if (customer && !add) setCustomer(null);
    };

    const updateSponsor = (updatedSponsor) => {
        setSponsors(prevSponsors =>
            prevSponsors.map(sponsor =>
                sponsor.uuid === updatedSponsor.uuid ? updatedSponsor : sponsor
            )
        );
    }

    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])

    const handleAdd = (edit = false) => {
        setAdd(!add);
        setModal("add")
        if (!edit) setCustomer(null);
    };

    const handleStatusDialogClose = (action) => {
        setStatusDialogOpen(!statusDialogOpen);
        if (action) {
            const data = {
                uuid: getUuidById(customerDeleteId)
            };

            changeStatusEgroup(data)
                .then((response) => {
                    const newStatus = response.data.data.status;
                    fetchSponsors();

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Grupo económico actualizado correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success',
                            },
                            close: true,
                        })
                    );
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al actualizar grupo económico.',
                            variant: 'alert',
                            alert: {
                                color: 'error',
                            },
                            close: true,
                        })
                    );
                });
        }
    };

    const getUuidById = (id) => {
        const foundObject = sponsors.find(item => item.id === id);
        return foundObject ? foundObject.uuid : "";
    };

    const handleClose = (action) => {
        setOpen(!open);
        if (action) {
            deleteEgroup(getUuidById(customerDeleteId))
                .then((response) => {
                    fetchSponsors()
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Grupo económico eliminado correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                }).catch((error) => {
                    console.error(error)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al eliminar grupo económico.',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
    };

    const fetchSponsors = () => {
        getEgroupsList()
            .then((response) => {
                // console.log(response)
                setSponsors(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                //setLoadingData(false)
            })
    }

    useEffect(() => {
        if (sponsors.length <= 0) {
            fetchSponsors()
        }
    }, [])

    const columns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center'
        },
        {
            Header: 'GRUPO ECONÓMICO',
            accessor: 'name',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Avatar alt="Avatar 1" size="sm" src={values.logo} />
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'PAIS',
            accessor: 'country.common_name'
        },
        {
            Header: 'ESTADO',
            accessor: 'status',
            Cell: ({ value }) => {
                switch (value) {
                    case 'FINISHED':
                        return <Chip label="Finalizado" className="bg-[#06BF7B21] text-green-500" size="small" />
                    case 'ACTIVE':
                        return <Chip label="Activo" className="bg-[#06BF7B21] text-green-500" size="small" />
                    case 'INACTIVE':
                        return <Chip label="Inactivo" className="bg-[#FF414121] text-[#FF4141]" size="small" />
                    default:
                        return <Chip label="Indefinido" className="bg-[#9399AA21] text-slate-800" size="small" />
                }
            }
        },
        {
            Header: 'SUSPENDER',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Switch defaultChecked={values.status === "INACTIVE"} checked={values.status === "INACTIVE"} onChange={(e) => {
                        e.stopPropagation();
                        handleStatusDialogClose();
                        setCustomerDeleteId(values.id);
                        setSponsorDeleteName(values.name);
                    }} className='z-50' />
                )
            }
        },
        {
            Header: 'ACCIONES',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="View"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Edit"
                        >
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCustomer(row.values);
                                    handleAdd(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Delete"
                        >
                            <IconButton
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setCustomerDeleteId(row.values.id);
                                    setSponsorDeleteName(row.values.name);
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        }
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <EGroupView data={sponsors[Number(row.id)]} />, [sponsors]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <div>
            <Stack direction='row' alignItems="center" spacing={2}>
                <div className='flex flex-row justify-end w-full py-4 mr-4'>
                    <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small" className='bg-orange-500 hover:bg-orange-400'>
                        Agregar grupo económico
                    </Button>
                </div>
            </Stack>
            {sponsors &&
                <Grid container xs={12} sm={12} lg={12} rowSpacing={2} columnSpacing={2}>
                    <SponsorList data={sponsors} loading={sponsors.length <= 0} setCustomer={setCustomer} handleAdd={handleAdd} setCustomerDeleteId={setCustomerDeleteId} setSponsorDeleteName={setSponsorDeleteName} handleAddSponsors={handleAddSponsors} handleClose={handleClose} handleStatusDialogClose={handleStatusDialogClose} />
                    {sponsors.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
                </Grid>
            }
            <AlertEGroupDelete title={customerDeleteId} open={open} handleClose={handleClose} name={sponsorDeleteName} />
            <AlertEGroupStatusChange title={customerDeleteId} open={statusDialogOpen} handleClose={handleStatusDialogClose} name={sponsorDeleteName} />
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                {/*<NewEGroup customer={customer} onCancel={handleAdd} open={add} setData={addSponsor} data={sponsors} updateSponsor={updateSponsor} setCustomer={setCustomer} />*/}
                {modal === "add" && <NewEGroup customer={customer} onCancel={handleAdd} open={add} setData={addSponsor} data={sponsors} updateSponsor={fetchSponsors} setCustomer={setCustomer} />}
                {modal === "sponsors" && <AddSponsorsToEGroup customer={customer} onCancel={handleAdd} open={add} setData={fetchSponsors} data={sponsors} updateSponsor={updateSponsor} setCustomer={setCustomer} />}
            </Dialog>
        </div>
    );
}

export default EGroupCards;