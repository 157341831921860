import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, CircularProgress, LinearProgress, Stack, Typography } from '@mui/material';

// third-party
import { useDropzone } from 'react-dropzone';

// project-imports
import RejectionFiles from './RejectionFiles';
import PlaceholderContent from './PlaceholderContent';
import { Add, ColorsSquare, DocumentText1 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import LeadPlaceHolderContent from './LeadPlaceHolderContent';

const DropzoneWrapper = styled('div')(({ theme }) => ({
    outline: 'none',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing(3, 1),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.paper,
    border: `1px dashed ${theme.palette.secondary.main}`,
    '&:hover': { opacity: 0.72, cursor: 'pointer' }
}));

const SingleFileLeadManager = ({ error, setFile, file, sx, loading }) => {
    const theme = useTheme();

    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        accept: '*/*', // Especificar solo archivos CSV si eso es lo que deseas
        multiple: false, // Asegurar que solo un archivo sea aceptado
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0]; // Solo tomar el primer archivo
            if (file) {
                setFile(file); // Establecer el archivo
            }
        }
    });

    const onRemove = () => {
        setFile(null); // Limpiar el archivo seleccionado
    };

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropzoneWrapper
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter'
                    }),
                    ...(file && {
                        display: "flex",
                        padding: '6% 3%',
                        justifyContent: "center",
                        textAlign: "center"
                    })
                }}
            >

                {file ? (
                    !loading ? (
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ height: 2 }}>
                            <DocumentText1 size="18" color="#FF8A65" />
                            <Typography variant="body2" className='font-semibold'>
                                {file.name}
                            </Typography>
                            <IconButton color="error" onClick={onRemove} >
                                <Add style={{ transform: 'rotate(45deg)' }} />
                            </IconButton>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ height: 2 }}>
                            <CircularProgress size={20} />
                            <Typography variant="body1" className='font-semibold'>
                                Subiendo archivo...
                            </Typography>
                        </Stack>
                    )
                ) : (
                    <>
                        <input {...getInputProps()} />
                        <LeadPlaceHolderContent />
                    </>

                )}
            </DropzoneWrapper>

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
        </Box>
    );
};

export default SingleFileLeadManager