import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
    Autocomplete
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';
import { Camera, SearchNormal, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';

// constant
const getInitialValues = (sponsor) => {
    // Asumimos valores predeterminados para campos que podrían no estar en `campaign`
    const initialValues = {
        name: '',
        country: '', // Usaremos el nombre común del país
        user_assigned: [], // Mantenemos la lista de usuarios asignados, aunque no se usa directamente en el formulario según tu código
        status: 'ACTIVE', // Estado por defecto, puede ajustarse según tu lógica
    };

    if (sponsor) {
        // console.log(sponsor.name)
        initialValues.name = sponsor.name;
        // initialValues.country = sponsor.country.common_name;
        // initialValues.sponsor = getNameById(sponsor.sponsor_id);
        initialValues.status = sponsor.status;
        // No ajustamos `user_assigned` aquí, ya que tu formulario no parece manejarlo directamente
    }

    return initialValues;
};

const AddSponsorsToEGroup = ({ customer, onCancel, open, setData, data, updateSponsor, setCustomer }) => {
    const theme = useTheme();
    const isCreating = !customer;
    const { getCountries, getEgroupsList, AddSponsorsToEgroup, getSponsorsFromEgroup } = useAuth();
    const [countries, setCountries] = useState([])
    const [egroups, setEgroups] = useState([])
    // console.log(customer)
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [avatar, setAvatar] = useState();
    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
        country: customer?.country?.id || '',
        egroup: customer?.economic_group_id || '',
        status: customer?.status || 'ACTIVE',
        user_assigned: []
    })
    const [sponsorData, setSponsorData] = useState(null)
    const [userAssignedIds, setUserAssignedIds] = useState([]);
    const [userAssignedDetails, setUserAssignedDetails] = useState([]);
    const [email, setEmail] = useState('');
    const [userAssigned, setUserAssigned] = useState([]);
    const [selectedSponsors, setSelectedSponsors] = useState([]);
    const [searchError, setSearchError] = useState('');
    const [removedUserIds, setRemovedUserIds] = useState([]);
    const [sponsors, setSponsors] = useState([]);

    // console.log(customer)

    const handleChange = (event, value) => {
        setSelectedSponsors(value);
        const ids = value.map(sponsor => sponsor.id);
        setFieldValue('sponsors_list', ids);
        // console.log(ids)
    };

    useEffect(() => {
        if (customer) {
            // console.log(data)
            // console.log(customer?.id)
            const sponsor = data.find(s => s.id === customer?.id);
            setSponsorData(sponsor)
            // console.log(sponsor)
            /*const userIds = sponsor?.user_assigned.map(user => {
                if (!userAssignedIds.includes(user.id)) {
                    return user.id;
                }
            });*/
            setInitialValuesState({})
            // setUserAssignedIds(userIds);
            setUserAssignedDetails(sponsor?.user_assigned || []);
            setAvatar(sponsor?.logo || "");
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: '',
                country: '',
                status: 'ACTIVE',
            })
            setAvatar("");
            setUserAssignedDetails([]);
        }
    }, [customer])


    useEffect(() => {
        if (selectedImage) {
            setAvatar(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        const fetchCountries = () => {
            getCountries()
                .then((response) => {
                    // console.log(response)
                    setCountries(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {

                })
        }

        const fetchEgroups = () => {
            getEgroupsList()
                .then((response) => {
                    // console.log(response)
                    setEgroups(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {

                })
        }

        if (open) {
            fetchCountries()
            fetchEgroups()
        }
    }, [open])

    const [openAlert, setOpenAlert] = useState(false);

    const handleAlertClose = () => {
        setOpenAlert(!openAlert);
        onCancel();
    };

    const handleStatusChange = (event) => {
        formik.setFieldValue('status', event.target.checked ? 'ACTIVE' : 'INACTIVE');
    };

    const handleSearch = async () => {
        try {
            if (email === null || email === "" || email === undefined) {
                setSearchError('Ingrese un sponsor válido.');
                return;
            }

            //const response = await searchSponsorUser(email);
            //const userData = response.data.data;
            const userData = sponsors.filter((sponsor) => sponsor.name.toLowerCase() == email.toLowerCase())
            // console.log(userData)
            if (userData) {

                if (userAssignedIds.includes(userData.id)) {
                    setSearchError('El sponsor ya está asignado.');
                    return;
                }

                setUserAssignedIds(prevIds => [...prevIds, userData.id]);
                setUserAssignedDetails(prevDetails => [...prevDetails, userData]);
                setFieldValue('sponsors_list', [...userAssignedIds, userData.id]);
                setEmail('');
                setSearchError('');
            } else {
                setSearchError('Sponsor no encontrado.');
            }
        } catch (error) {
            console.error('Error buscando sponsor:', error);
            setSearchError('Sponsor no encontrado.');
        }
    };

    const handleDeleteUser = (userToDelete) => {
        const newUserAssignedIds = userAssignedIds.filter(id => id !== userToDelete.id);
        const newUserAssignedDetails = userAssignedDetails.filter(user => user.id !== userToDelete.id);

        if (customer) {
            if (!removedUserIds.includes(userToDelete.id)) {
                setRemovedUserIds(prevIds => [...prevIds, userToDelete.id]);
            }
        }

        setUserAssignedIds(newUserAssignedIds);
        setUserAssignedDetails(newUserAssignedDetails);
        setFieldValue('sponsors_list', newUserAssignedIds);
    };

    useEffect(() => {
        if (customer?.logo) {
            setAvatar(customer.logo);
        }
    }, [customer?.logo]);

    useEffect(() => {
        const fetchSponsors = () => {
            getSponsorsFromEgroup(customer.uuid)
                .then((response) => {
                    setSponsors(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
        if (customer?.uuid) {
            fetchSponsors()
        }
    }, [customer])

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: customer ? Yup.object({
        }) : Yup.object({
            sponsors_list: Yup.array()
                .of(Yup.number().positive('ID del usuario debe ser positivo').required('ID del usuario es requerido'))
                .min(1, 'Debe haber al menos un usuario asignado')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                let formData = new FormData();
                formData.append("uuid", customer.uuid)
                for (let i = 0; i < values.sponsors_list.length; i++) {
                    formData.append('sponsors_list[]', values.sponsors_list[i]);
                }
                // for (var pair of formData.entries()) {
                //     console.log(`${pair[0]}: ${pair[1]}`);
                // }
                AddSponsorsToEgroup(formData)
                    .then((response) => {
                        // console.log(response.data)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Sponsors agregados exitosamente',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                        setData(response.data.data)
                        //setData();
                        resetForm();
                    })
                    .catch((error) => {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: error.message,
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: false
                            })
                        )
                    })
                    .finally(() => {
                        setSubmitting(false);
                        onCancel();
                    })
                setSubmitting(false);
                onCancel();
            } catch (error) {
                // console.log(error)
            }
        }
    });

    const handleCancel = () => {
        formik.resetForm()
        setCustomer()
        onCancel()
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle> Agregar Sponsors</DialogTitle>
                        <Divider />
                        <DialogContent sx={{ p: 2.5 }}>
                            <Grid container spacing={3}>
                                {sponsors &&
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={sponsors}
                                            getOptionLabel={(option) => option?.name}
                                            onChange={handleChange}
                                            filterSelectedOptions
                                            renderInput={(params) => <TextField {...params} placeholder="Sponsors" />}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    p: 1
                                                },
                                                '& .MuiAutocomplete-tag': {
                                                    bgcolor: 'primary.lighter',
                                                    border: '1px solid',
                                                    borderColor: 'primary.light',
                                                    '& .MuiSvgIcon-root': {
                                                        color: 'primary.main',
                                                        '&:hover': {
                                                            color: 'primary.dark'
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="end" alignItems="center">

                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleCancel}>
                                            Cancelar
                                        </Button>
                                        <Button type="submit" variant="contained" disabled={isSubmitting} className="bg-orange-500">
                                            Agregar sponsor
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
            {/* {!isCreating && <AlertCustomerDelete title={customer.fatherName} open={openAlert} handleClose={handleAlertClose} />} */}
        </>
    );
}

export default AddSponsorsToEGroup