import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    Button,
    Chip,
    Dialog,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
import {
    HeaderSort,
    SortingSelect,
    TablePagination,
} from 'components/third-party/ReactTable';

// assets
import { Add, AddSquare, Edit, Eye, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import NewCampaign from './NewCampaign';
import useDataStore from 'store/useDataStore';
import CampaignView from 'sections/apps/campaign/CampaignView';
import CustomTooltip from 'components/@extended/Tooltip';
import AlertCampaignDelete from './AlertCampaignDelete';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import AlertCampaignStatusChange from './AlertCampaignStatusChange';
import NewAgentsToCampaign from 'pages/agents/NewAgentsToCampaign';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatID } from 'utils/appUtils';
import useAuthStore from 'store/useAuthStore';

const avatarImage = require.context('assets/images/users', true);
const getNameById = (id) => {
    const currentState = useDataStore.getState();
    const data = currentState.sponsor;
    const foundObject = data.find(item => item.id === id);
    return foundObject ? foundObject.name : "";
};

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const { user } = useAuthStore();

    const isAdmin = () => {
        return user?.rol?.includes("Super Admin") || user?.rol?.includes("Admin") || false;
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,

    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
    );

    return (
        <>
            {/* <TableRowSelection selected={Object.keys(selectedRowIds).length} /> */}
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        {isAdmin() && <Button variant="contained" startIcon={<Add />} size="small" className='bg-orange-500 hover:bg-orange-400' onClick={handleAdd}>
                            Crear Campaña
                        </Button>}
                        {/* <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'users-list.csv'} /> */}
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                            page.map((row, i) => {
                                prepareRow(row);
                                const rowProps = row.getRowProps();
                                return (
                                    <Fragment key={i}>
                                        <TableRow
                                            {...row.getRowProps()}
                                            // onClick={() => {
                                            //     row.toggleRowSelected();
                                            // }}
                                            sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                        >
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                    </Fragment>
                                );
                            })
                        ) : (
                            // Renderiza esta fila si no hay datos
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No hay campañas disponibles
                                </TableCell>
                            </TableRow>
                        )}

                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}

const CampaignList = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [loadingData, setLoadingData] = useState(false)
    const { campaign, setCampaign, removeCampaignById } = useDataStore()
    const [countries, setCountries] = useState([])
    const [data, setData] = useState(campaign);
    const { getCampaignList, getCountryList, deleteCampaign, changeStatusCampaign } = useAuth();
    const [open, setOpen] = useState(false);
    const [statusDialogOpen, setStatusDialogOpen] = useState(false)
    const [customer, setCustomer] = useState(null);
    const [campaignDeleteName, setCampaignDeleteName] = useState("")
    const [campaignDeleteId, setCampaignDeleteId] = useState('');
    const [add, setAdd] = useState(false);
    const [modal, setModal] = useState("add");
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    const { user } = useAuthStore();

    const isAdmin = () => {
        return user?.rol?.includes("Super Admin") || user?.rol?.includes("Admin") || false;
    }

    const isEditor = () => {
        return user?.rol?.includes("Editor") || false;
    }

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Lista de Campañas', path: '/campaigns/list' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const handleAdd = () => {
        setAdd(!add);
        setModal("add")
        if (customer && !add) setCustomer(null);
    };

    const handleAddAgents = () => {
        setAdd(!add);
        setModal("agents")
        if (customer && !add) setCustomer(null);
    };

    const updateData = (updated) => {
        setData(prev =>
            prev.map(campaign =>
                campaign.uuid === updated.uuid ? updated : campaign
            )
        );
    }

    const handleClose = (action) => {
        setOpen(!open);
        // console.log(action)
        if (action) {
            // console.log("Deleting: ", campaignDeleteName)
            // console.log("ID: ", getUuidById(campaignDeleteId))
            deleteCampaign(getUuidById(campaignDeleteId))
                .then((response) => {
                    // console.log(response.data)
                    // removeCampaignById(campaignDeleteId)
                    setCampaign(currentCampaigns => currentCampaigns.filter(campaign => campaign.id !== campaignDeleteId));
                    setData(currentCampaigns => currentCampaigns.filter(campaign => campaign.id !== campaignDeleteId))
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Campaña eliminada correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                }).catch((error) => {
                    console.error(error)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al eliminar campaña.',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
    };

    const handleStatusDialogClose = (action) => {
        setStatusDialogOpen(!statusDialogOpen); // Cerrar el diálogo
        if (action) {
            // console.log("Modify: ", campaignDeleteName);
            // console.log("ID: ", getUuidById(campaignDeleteId));
            const data = getUuidById(campaignDeleteId);

            changeStatusCampaign(data)
                .then((response) => {
                    // Suponiendo que tu función changeStatusCampaign devuelve el nuevo estado de la campaña
                    const newStatus = response.data.data.status; // Asumiendo que el servidor devuelve el nuevo estado en esta propiedad
                    // console.log(response.data.data)
                    // Actualizar el estado de la campaña en tus datos locales
                    setData((currentData) =>
                        currentData.map((campaign) => {
                            if (campaign.id === campaignDeleteId) {
                                return { ...campaign, status: newStatus };
                            }
                            return campaign;
                        })
                    );

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Campaña actualizada correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success',
                            },
                            close: true,
                        })
                    );
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al actualizar campaña.',
                            variant: 'alert',
                            alert: {
                                color: 'error',
                            },
                            close: true,
                        })
                    );
                });
        }
    };

    const getUuidById = (id) => {
        // console.log(id)
        const foundObject = campaign.find(item => item.id === id);
        // console.log(foundObject)
        return foundObject ? foundObject.uuid : "";
    };

    useEffect(() => {
        const fetchCampaign = () => {
            getCampaignList()
                .then((response) => {
                    // console.log(response.data.data)
                    setData(response.data.data)
                    setCampaign(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
        if (data.length <= 0) {
            fetchCampaign()
        }
    }, [])

    useEffect(() => {
        const fetchCountries = () => {
            getCountryList()
                .then((response) => {
                    // console.log(response.data)
                    // console.log(response.data)
                    // setData(response.data.data)
                    setCountries(response.data.data)
                }).catch((error) => {
                    // setData([])
                }).finally(() => {
                    // setLoadingData(false)
                })
        }

        fetchCountries()
    }, [])

    const columns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                return (
                    <Typography variant="body1">{formatID(row.values.id)}</Typography>
                );
            }
        },
        {
            Header: 'CAMPAÑA',
            accessor: 'name',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack spacing={0}>
                        <Typography variant="subtitle1">{values.name}</Typography>
                    </Stack>
                    // <Stack direction="row" spacing={1.5} alignItems="center">
                    // <Avatar alt="Avatar 1" size="sm" src={values.logo} />
                    // {/*<img className="rounded-full w-8 h-8" alt={values.name} src={values.logo} />*/}
                    // <Stack spacing={0}>
                    //     <Typography variant="subtitle1">{values.name}</Typography>
                    // </Stack>
                    // </Stack>
                );
            }
        },
        {
            Header: 'PAÍS',
            accessor: 'country',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Typography className="text-orange-400" variant="subtitle1">{values?.country?.common_name || ""}</Typography>
                )
            }
        },
        {
            Header: 'SPONSOR',
            accessor: "sponsor_id",
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Typography className="text-orange-400" variant="subtitle1">{getNameById(values.sponsor_id)}</Typography>
                );
            }
        },
        {
            Header: 'ASIGNADOS',
            accessor: "user_assigned",
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <div className='flex flex-col items-start'>
                        <AvatarGroup max={3}>
                            {values.user_assigned.map((user) => (
                                // Asegurarse de que cada Avatar esté envuelto por un Tooltip individualmente
                                <CustomTooltip key={user.id} title={user.name} placement="top" arrow color="black">
                                    <div className='ml-[-5px]'>
                                        <Avatar alt={user.name} src={user.profile_img} />
                                    </div>
                                </CustomTooltip >
                            ))}
                        </AvatarGroup>
                    </div>
                )
            }
        },
        {
            Header: 'ESTADO',
            accessor: 'status',
            Cell: ({ value }) => {
                switch (value) {
                    case 'ACTIVE':
                        return <Chip className={"bg-[#06BF7B21] text-green-500"} label="Activo" size="small" variant="filled" />;
                    case 'INACTIVE':
                        return <Chip className={"bg-[#FF414121] text-[#FF4141]"} label="Pausado" size="small" variant="filled" />;
                    case 'FINISHED':
                        return <Chip label="Finalizada" size="small" variant="filled" />;
                    default:
                        return <Chip label="Inactiva" size="small" variant="filled" />;
                }
            }
        },
        {
            Header: 'SUSPENDER',
            accessor: 'uuid',
            Cell: ({ row }) => {
                const { values } = row;
                // console.log(values)
                return (
                    <Switch defaultChecked={values.status === "INACTIVE"} checked={values.status === "INACTIVE"} onChange={(e) => {
                        e.stopPropagation();
                        handleStatusDialogClose();
                        setCampaignDeleteId(values.id);
                        setCampaignDeleteName(values.name);
                    }} className='z-50' />
                )
            }
        },
        {
            Header: 'ACCIONES',
            accessor: 'logo',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Ver"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Agregar Agentes"
                        >
                            <IconButton
                                color="warning"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCustomer(row.values);
                                    setCampaignDeleteId(row.values.id);
                                    handleAddAgents();
                                }}
                            >
                                <AddSquare />
                            </IconButton>
                        </Tooltip>
                        {(isAdmin() || isEditor()) &&
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                            opacity: 0.9
                                        }
                                    }
                                }}
                                title="Editar"
                            >
                                <IconButton
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCustomer(row.values);
                                        handleAdd();
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        }
                        {(isAdmin() || isEditor()) &&
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                            opacity: 0.9
                                        }
                                    }
                                }}
                                title="Eliminar"
                            >
                                <IconButton
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose();
                                        setCampaignDeleteId(row.values.id);
                                        setCampaignDeleteName(row.values.name);
                                    }}
                                >
                                    <Trash />
                                </IconButton>
                            </Tooltip>
                        }
                    </Stack>
                );
            }
        }
        // Aquí puedes ajustar las demás columnas como 'ACCIONES' según necesites...
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <CampaignView data={data[Number(row.id)]} />, [data]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <MainCard content={false}>
            <ScrollX>
                <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
                {data.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
            </ScrollX>
            <AlertCampaignDelete title={campaignDeleteId} open={open} handleClose={handleClose} name={campaignDeleteName} />
            <AlertCampaignStatusChange title={campaignDeleteId} open={statusDialogOpen} handleClose={handleStatusDialogClose} name={campaignDeleteName} />
            {/* add customer dialog */}
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                {modal === "add" && <NewCampaign customer={customer} onCancel={handleAdd} open={add} setData={setData} allZones={countries} data={campaign} updateData={updateData} />}
                {modal === "agents" && <NewAgentsToCampaign customer={customer} onCancel={handleAdd} open={add} setData={setData} campaignId={campaignDeleteId} />}
            </Dialog>
        </MainCard>
    );
}

CampaignList.propTypes = {
    row: PropTypes.object,
    values: PropTypes.object,
    avatar: PropTypes.object,
    message: PropTypes.string,
    fatherName: PropTypes.string,
    email: PropTypes.string,
    value: PropTypes.object,
    isExpanded: PropTypes.bool,
    toggleRowExpanded: PropTypes.func,
    getToggleAllPageRowsSelectedProps: PropTypes.func,
    id: PropTypes.number
};

export default CampaignList