export const formatID = (number) => {
    if (number < 10) {
        return '000' + number;
    } else if (number < 100) {
        return '00' + number;
    } else if (number < 1000) {
        return '0' + number;
    } else {
        return number;
    }
}

export const shortDate = (string) => {
    const objectDate = new Date(string);
    const day = objectDate.getDate();
    const month = objectDate.getMonth();
    const year = objectDate.getFullYear();
    const formatDay = `${day < 10 ? '0' + day : day}`;
    const formatMonth = `${month < 10 ? '0' + month : month}`
    const format = `${formatDay}/${formatMonth}/${year}`;
    return format;
}

export const formatedDate = (date) => {
    const objectDate = date || new Date();
    const day = objectDate.getDate();
    const month = objectDate.getMonth() + 1;
    const year = objectDate.getFullYear();
    const formatDay = `${day < 10 ? '0' + day : day}`;
    const formatMonth = `${month < 10 ? '0' + month : month}`
    const format = `${year}-${formatMonth}-${formatDay}`;
    return format;
};

export const formatDateShort = (dateString) => {
    try {
        const [datePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${year}/${month}/${day}`;
    } catch (err) {
        console.error(err)
        return dateString;
    }
};

export const isObject = (obj) => {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
};