import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// project import
import { ThemeMode } from 'config';

// chart options
const areaChartOptions = {
  chart: {
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    width: 1
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '45%',
      borderRadius: 4
    }
  },
  grid: {
    strokeDashArray: 4
  }
};

// ==============================|| CHART - REPEAT CUSTOMER CHART ||============================== //
const VapBigChart = ({ dataGraph, campaignSelected }) => {
  const theme = useTheme();

  const mode = theme.palette.mode;
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories: dataGraph?.categories,
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: false,
          color: line
        },
        axisTicks: {
          show: false
        },
        tickAmount: 11,
        title: {
          text: "Progreso Total de Llamada"
        }
      },
      yaxis: {
        categories: ['0k', '1k', '2k', '3k', '4k', '5kk'],
        labels: {
          style: {
            colors: [secondary]
          }
        },
        title: {
          text: "Palabras por Minutos"
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        x: {
          show: false
        },
        followCursor: true,
        custom: ({ series, seriesIndex, dataPointIndex }) => {
          return `<div class="tooltip" style="padding: 10px;">${dataGraph?.tooltips[dataPointIndex]}</div>`;
        }
      },
      markers: {
        size: 5,
        colors: [theme.palette.primary.main],
        strokeColors: theme.palette.background.paper,
        strokeWidth: 2,
        hover: {
          size: 7,
          sizeOffset: 3
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return dataGraph?.flags[opts.dataPointIndex];
        },
        offsetX: 0,
        offsetY: -10,
        style: {
          fontSize: '13px',
          colors: [theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.main : "#fff"],
        },
        background: {
          enabled: true,
          foreColor: theme.palette.mode === ThemeMode.DARK ? "#fff" : '#000',
          padding: 6,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.main : "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.1
          }
        },
        dropShadow: {
          enabled: false
        }
      },
      theme: {
        mode: mode === ThemeMode.DARK ? 'dark' : 'light'
      },
      noData: {
        text: 'Cargando...'
      }
    }));
  }, [mode, primary, secondary, line, theme, campaignSelected, dataGraph]);

  const initialSeries = [
    {
      name: 'Score Graph',
      data: dataGraph?.seriesData
    }
  ];

  const [series, setSeries] = useState(initialSeries);

  useEffect(() => {
    if (dataGraph) {
      setSeries([
        {
          name: 'Score Graph',
          data: dataGraph?.seriesData
        }
      ]);
    } else {
      setSeries([]);
    }
  }, [dataGraph, campaignSelected]);

  return <ReactApexChart options={options} series={series} type="area" height={260} />;
};

export default VapBigChart;