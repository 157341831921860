// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart, User, Data, VolumeMute, Category, Personalcard, People } from 'iconsax-react';
import useDataStore from 'store/useDataStore';
const state = useDataStore.getState();
// icons
const icons = {
  user: User,
  sponsor: Data,
  campaign: VolumeMute,
  dashboard: Category,
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  agent: Personalcard,
  egroup: People
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = [{
  id: 'group-widget',
  title: "GESTIÓN GENERAL",
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'collapse',
      icon: icons.dashboard,
      children: [
        {
          id: 'sponsor-list',
          title: (
            <>
              <FormattedMessage id="all-sponsor" />
            </>
          ),
          type: 'item',
          icon: icons.sponsor,
          url: '/vap'
        },
        {
          id: 'campaign-list-menu',
          title: (
            <>
              <FormattedMessage id="all-campaign" />
            </>
          ),
          type: 'item',
          icon: icons.campaign,
          url: '/vap/all-campaign'
        },
        /*{
          id: 'sponsor-global-menu',
          title: (
            <>
              <FormattedMessage id="global-sponsor" />
            </>
          ),
          type: 'item',
          icon: icons.sponsor,
          url: '/sponsors/global'
        },*/
      ]
    },
    {
      id: 'egroups',
      title: <FormattedMessage id="egroup" />,
      type: 'item',
      url: '/vap/egroups/list',
      icon: icons.egroup
    },
  ]
},{
  id: 'group-business',
  title: "GESTIÓN DE NEGOCIO",
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'users',
      title: <FormattedMessage id="usuarios" />,
      type: 'item',
      url: '/vap/users/list',
      icon: icons.user,
    },
    {
      id: 'sponsor',
      title: <FormattedMessage id="sponsor" />,
      type: 'item',
      url: '/vap/sponsors',
      icon: icons.sponsor
    },
    {
      id: 'campaign',
      title: <FormattedMessage id="campaign" />,
      type: 'item',
      url: '/vap/campaigns/list',
      icon: icons.campaign
    },
    {
      id: 'agents',
      title: <FormattedMessage id="agent" />,
      type: 'item',
      url: '/vap/agents/list',
      icon: icons.agent
    },
  ]
}]

export default widget;
