import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    Button,
    Chip,
    Dialog,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
    CSVExport,
    HeaderSort,
    IndeterminateCheckbox,
    SortingSelect,
    TablePagination,
    TableRowSelection
} from 'components/third-party/ReactTable';

import AddCustomer from 'sections/apps/customer/AddCustomer';
import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';

import makeData from 'data/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, DirectboxSend, DirectSend, Edit, Eye, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import useDataStore from 'store/useDataStore';
import useAuthStore from 'store/useAuthStore';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import AlertUserStatusChange from './AlertUserStatusChange';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatID } from 'utils/appUtils';
import AlertUserResend from './AlertUserResend';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuthStore();
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    // useEffect(() => {
    //     if (matchDownSM) {
    //         setHiddenColumns(['age', 'contact', 'visits', 'status', 'avatar', 'age']);
    //     } else {
    //         setHiddenColumns(['avatar', 'age']);
    //     }
    //     // eslint-disable-next-line
    // }, [matchDownSM]);

    return (
        <>
            {/*<TableRowSelection selected={Object.keys(selectedRowIds).length} />*/}
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        {user?.rol[0] === "Admin" || user?.rol[0] === "Super Admin" && (
                            <>
                                <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small" className='bg-orange-500 hover:bg-orange-400'>
                                    Agregar usuario
                                </Button>
                                <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'users-list.csv'} />
                            </>
                        )}
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();

                            return (
                                <Fragment key={i}>
                                    <TableRow
                                        {...row.getRowProps()}
                                        onClick={() => {
                                            row.toggleRowSelected();
                                        }}
                                        sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                </Fragment>
                            );
                        })}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}

// ReactTable.propTypes = {
//     columns: PropTypes.array,
//     data: PropTypes.array,
//     getHeaderProps: PropTypes.func,
//     handleAdd: PropTypes.func,
//     renderRowSubComponent: PropTypes.any
// };


const UserList = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    // const data = useMemo(() => makeData(5), []);
    const [loadingData, setLoadingData] = useState(true)
    // const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const [customerDeleteName, setCustomerDeleteName] = useState("")
    const [userResend, setUserResend] = useState(null);
    const { users, addUser, setUsers } = useDataStore();
    const [add, setAdd] = useState(false);
    const { getUserList, deleteUser, updateUser, resendInvitationEmail } = useAuth();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false)
    const [resendDialogOpen, setResendDialogOpen] = useState(false)
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Lista de Usuarios', path: '/users/list' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const handleAdd = () => {
        setAdd(!add);
        if (customer && !add) {
            setCustomer(null);
        }
    };

    const handleClose = () => {
        setOpen(!open);
    };

    const getUuidById = (id) => {
        // console.log(id)
        const foundObject = users.find(item => item.id === id);
        return foundObject ? foundObject.uuid : "";
    };

    const getUserById = (id) => {
        const foundObject = users.find(item => item.id === id);
        return foundObject;
    };

    const handleResendInvitation = () => {
        try {
            let formData = new FormData();
            formData.append("uuid", userResend?.uuid)

            resendInvitationEmail(formData)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.success) {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Se ha reenviado la invitación correctamente',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                    } else {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: `Error al enviar invitación: ${response.data?.message || ""}`,
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: false
                            })
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    )
                })
                .finally(() => {
                    setUserResend(null);
                    setResendDialogOpen(false);
                })
        } catch (err) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: err.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            )
        }
    }

    const handleResendDialog = () => {
        setResendDialogOpen(!resendDialogOpen);
    }

    const handleStatusDialog = () => {
        setStatusDialogOpen(!statusDialogOpen);
    }

    const handleStatusDialogClose = (customer) => {
        setStatusDialogOpen(!statusDialogOpen); // Cerrar el diálogo
        try {
            let formData = new FormData();
            //if (selectedImage) formData.append("logo", selectedImage);
            formData.append("status", customer.status === "inactive" ? "active" : "inactive")

            // for (var pair of formData.entries()) {
            //     console.log(`${pair[0]}: ${pair[1]}`);
            // }

            updateUser(customer?.uuid, formData)
                .then((response) => {
                    // console.log(response.data)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Estado actualizado exitosamente',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    fetchUsers()
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: error.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    )
                })
                .finally(() => {
                    //setSubmitting(false);
                    //onCancel();
                })
            resetForm();
        } catch (error) {
            console.error(error);
        }
    }

    const handleDelete = () => {
        setOpen(!open);
        // console.log("Deleting: ", customerDeleteName)
        // console.log("ID: ", getUuidById(customerDeleteId))
        deleteUser(getUuidById(customerDeleteId))
            .then((response) => {
                // console.log(response.data)
                fetchUsers();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Usuario eliminado correctamente.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            }).catch((error) => {
                console.error(error)
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Error al eliminar usuario.',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }).finally(() => {
                //setLoadingData(false)
            })
    };

    const fetchUsers = () => {
        getUserList()
            .then((response) => {
                setUsers(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                //setLoadingData(false)
            })
    }

    useEffect(() => {
        if (users.length <= 0) {
            fetchUsers()
        }
    }, [])

    const columns = useMemo(() => [
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Typography variant="body1">{formatID(values.id)}</Typography>
                );
            }
        },
        {
            Header: 'NOMBRE',
            accessor: 'name',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {values.avatar ? (
                            <Avatar alt={values.name} size="sm" src={values.avatar} />
                        ) : (
                            <Avatar alt={values.name} size="sm">{values.name.charAt(0).toUpperCase()}</Avatar>
                        )}
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'EMAIL',
            accessor: 'email'
        },
        {
            Header: 'ROL',
            accessor: row => row.user_type?.name || "",
        },
        {
            Header: 'PAÍS',
            accessor: row => row?.country?.common_name || "",
        },
        {
            Header: 'ESTADO',
            accessor: 'status',
            Cell: ({ value }) => {
                switch (value) {
                    case 'active':
                        return <Chip className={"bg-[#06BF7B21] text-green-500"} label="Activo" size="small" variant="filled" />;
                    case 'pending':
                        return <Chip className={"bg-[#FEBF2021] text-[#A47A10]"} label="Pendiente" size="small" variant="filled" />;
                    case 'inactive':
                        return <Chip className={"bg-[#FEBF2021] text-[#A47A10]"} label="Supendido" size="small" variant="filled" />;
                    default:
                        return <Chip label="Indefinido" size="small" variant="filled" />;
                }
            }
        },
        {
            Header: 'SUSPENDER',
            //accessor: row => <Switch defaultChecked={row.suspended} className='z-50' />
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Switch defaultChecked={values.status === "inactive"} checked={values.status === "inactive"} onChange={(e) => {
                        e.stopPropagation();
                        handleStatusDialogClose();
                        setCustomerDeleteId(values.id);
                        setCustomerDeleteName(values.name);
                    }} className='z-50' />
                )
            }
        },
        {
            Header: 'ACCIONES',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                // console.log(row)
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="start" spacing={0}>
                        {row?.original?.status === "pending" && (
                            <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                            opacity: 0.9
                                        }
                                    }
                                }}
                                title="Reenviar Invitación"
                            >
                                <IconButton
                                    color="warning"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // row.toggleRowExpanded();
                                        setUserResend(row.original)
                                        setCustomerDeleteName(row.values.email);
                                        handleResendDialog();
                                    }}
                                >
                                    <DirectSend />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Ver"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Editar"
                        >
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    //setCustomer(row.values);
                                    setCustomerDeleteId(row.values.id)
                                    //setCustomer(getUserById(row.values.id))
                                    handleAdd();
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Eliminar"
                        >
                            <IconButton
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setCustomerDeleteId(row.values.id);
                                    setCustomerDeleteName(row.values.name);
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        }
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <CustomerView data={users[Number(row.id)]} />, [users]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <MainCard content={false}>
            <ScrollX>
                <ReactTable columns={columns} data={users} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
                {users.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
            </ScrollX>
            <AlertCustomerDelete title={customerDeleteName} open={open} handleClose={handleClose} handleDelete={handleDelete} />
            <AlertUserStatusChange title={customerDeleteId} open={statusDialogOpen} handleClose={handleStatusDialog} updateData={() => handleStatusDialogClose(getUserById(customerDeleteId))} name={customerDeleteName} />
            <AlertUserResend open={resendDialogOpen} handleClose={handleResendDialog} resendInvitation={handleResendInvitation} email={customerDeleteName} />
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                <AddCustomer customer={getUserById(customerDeleteId)} onCancel={handleAdd} open={add} setData={addUser} updateData={fetchUsers} setCustomerDeleteId={setCustomerDeleteId} />
            </Dialog>
        </MainCard>
    );
}

UserList.propTypes = {
    row: PropTypes.object,
    values: PropTypes.object,
    avatar: PropTypes.object,
    message: PropTypes.string,
    fatherName: PropTypes.string,
    email: PropTypes.string,
    value: PropTypes.object,
    isExpanded: PropTypes.bool,
    toggleRowExpanded: PropTypes.func,
    getToggleAllPageRowsSelectedProps: PropTypes.func,
    id: PropTypes.number
};

export default UserList