import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Skeleton, Button, Typography, TextField } from '@mui/material';

// assets
import { ArrowDown, ArrowUp, Calendar, CloudChange, Messages3, PresentionChart, Personalcard, Call } from 'iconsax-react';
import VapBigChartCard from 'sections/widget/dashboard/VapBigChartCard';
import TopAgentChart from 'sections/widget/dashboard/TopAgentChart';
import AiHubActivityCard from 'sections/widget/dashboard/AiHubActivityCard';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import useAuth from 'hooks/useAuth';
import VapDataCard from 'components/cards/dashboard/VapDataCard';
import VapStatusChart from 'sections/widget/dashboard/VapStatusChart';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MenuCampaignThread from './MenuCampaignThread';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import useDataStore from 'store/useDataStore';
import { ThemeMode } from 'config';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import { formatedDate } from 'utils/appUtils';

const VapDataPercentage = ({ value, loading }) => {
    return (
        <div className={`flex flex-row justify-start ${value > 0 ? "text-green-500" : "text-red-500"} items-center space-x-1 flex-nowrap text-nowrap`}>
            <p className="text-nowrap">{loading ? <Skeleton width={50} /> : `${parseFloat(value)?.toFixed(1)}%`}</p>
            {!loading && (
                <VapDataArrow value={value} />
            )}
        </div>
    )
}

const VapDataArrow = ({ value }) => {
    if (value > 0) {
        return <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} />
    } else {
        return <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} />
    }
}

const VapDataList = ({ data, theme, loading }) => {
    const getColorBasedOnData = (data) => {
        if (data) {
            return data > 0 ? theme.palette.success.main : theme.palette.success.main;
        } else {
            return theme.palette.primary.main;
        }
    }


    return (
        <>
            <Grid item xs={12} sm={3} lg={2.4}>
                <VapDataCard
                    title="Llamadas"
                    subtitle="Auditadas"
                    count={data?.total_archives_process ? Math.trunc(data?.total_archives_process) : 0}
                    showIcon={false}
                    showOptions={false}
                    percentage={<VapDataPercentage value={data?.percentage_archives_process ? data?.percentage_archives_process : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_archives_process)} data={data?.chart_archives_process ? data.chart_archives_process : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={3} lg={2.4}>
                <VapDataCard
                    title="Llamadas"
                    subtitle="Afectadas"
                    count={Math.trunc(data?.total_calls_affected)}
                    showIcon={false}
                    showOptions={false}
                    percentage={<VapDataPercentage value={data?.percentage_calls_affected ? data.percentage_calls_affected : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_calls_affected)} data={data?.chart_calls_affected ? data.chart_calls_affected : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={3} lg={2.4}>
                <VapDataCard
                    title="Aceptación"
                    subtitle="De compra (MAC)"
                    count={`${Math.trunc(data?.total_buy_accepted) || 0}%`}
                    color="success"
                    showIcon={false}
                    showOptions={false}
                    iconPrimary={<Calendar color={theme.palette.success.main} />}
                    percentage={<VapDataPercentage value={data?.percentage_buy_accepted ? data.percentage_buy_accepted : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_buy_accepted)} data={data?.chart_buy_accepted ? data.chart_buy_accepted : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={3} lg={2.4}>
                <VapDataCard
                    title="Llamadas"
                    subtitle="No Leídas"
                    count={`${data?.unread_calls ? data?.unread_calls?.toFixed(1) : 0}`}
                    color="error"
                    showIcon={false}
                    showOptions={false}
                    iconPrimary={<CloudChange color={theme.palette.error.dark} />}
                    percentage={<VapDataPercentage value={data?.percentage_unread_calls ? data?.percentage_unread_calls?.toFixed(2) : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_unread_calls)} data={data?.chart_unread_calls ? data.chart_unread_calls : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={3} lg={2.4}>
                <VapDataCard
                    title="Minutos"
                    subtitle="TMO Promedio"
                    count={`${data?.tmo ? Math.trunc(data.tmo) : 0}`}
                    countAux="min"
                    color="error"
                    showIcon={false}
                    showOptions={false}
                    iconPrimary={<CloudChange color={theme.palette.error.dark} />}
                    percentage={<VapDataPercentage value={data.percentage_tmo ? data.percentage_tmo : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data.percentage_tmo)} data={data.chart_tmo ? data.chart_tmo : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
        </>
    )
}

const AllCampaign = () => {
    const theme = useTheme();
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { getCampaignKpi, getCampaignsAndSponsorTree, getCampaignFromSponsor, getCampaignRecomendation, getCampaignTopAgents } = useAuth();
    const { campaignSelected, setCampaignSelected } = useDataStore();
    const [campaignSelectedName, setCampaignSelectedName] = useState("")
    const [tree, setTree] = useState([]);
    const [kpi, setKpi] = useState(null);
    const [recomendations, setRecomendations] = useState([])

    const [kpiLoading, setKpiLoading] = useState(true)

    const [open, setOpen] = useState(null);
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());

    useEffect(() => {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        setSelectedInitialDate(dateObj);
    }, []);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsor', path: `/vap/${campaignSelected?.sponsor.uuid || ""}` },
            { label: campaignSelected.sponsor.name || "", path: `/vap/${campaignSelected?.sponsor.uuid || ""}` },
            { label: 'Campañas', path: '/vap/all-campaign' },
            { label: campaignSelected.campaign.name || "", path: `/vap/all-campaign/${campaignSelected?.campaign.uuid || ""}` },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, campaignSelected]);

    const handleClick = (id) => {
        setOpen(open !== id ? id : '');
    };

    const handleConversationClick = () => {
        navigate(`/vap/voice-analyzer/${campaignSelected?.campaign?.uuid}`);
    }

    const handleCallsClick = () => {
        navigate(`/vap/campaigns/calls/${campaignSelected?.campaign?.uuid}`);
    }

    const handleStatsClick = () => {
        setCampaignSelected({
            campaign: campaignSelected?.campaign,
            sponsor: campaignSelected?.sponsor
        });
        navigate(`/vap/all-campaign/stats/${campaignSelected?.campaign?.uuid}`);
    }

    const handleAgentsClick = () => {
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    const handleListItemClick = (event, campaign, sponsor) => {
        navigate(`/vap/all-campaign/${campaign?.uuid}`);
        setCampaignSelected({
            campaign: campaign,
            sponsor: sponsor
        });
    };

    useEffect(() => {
        const fetchTree = () => {
            setKpiLoading(true)
            getCampaignsAndSponsorTree()
                .then((response) => {
                    setTree(response.data.data)
                    if (campaignId) {
                        let found = false;
                        let foundCampaign = null;
                        let foundSponsor = null;
                        for (const sponsor of response.data.data) {
                            if (campaignId.length > 5) {
                                foundCampaign = sponsor.campaings.find(campaign => campaign.uuid.toString() === campaignId);
                            } else {
                                foundCampaign = sponsor.campaings.find(campaign => campaign.id.toString() === campaignId);
                            }
                            if (foundCampaign) {
                                foundSponsor = sponsor
                                setCampaignSelected({ campaign: foundCampaign, sponsor: sponsor });
                                setOpen(sponsor.id);
                                found = true;
                                break;
                            }
                        }

                        if (found) {
                            setCampaignSelected({ campaign: foundCampaign, sponsor: foundSponsor });
                            setOpen(foundSponsor.id);
                        } else {
                            setCampaignSelected({
                                campaign: response.data.data[0].campaings[0],
                                sponsor: response.data.data[0]
                            });
                        }
                    } else {
                        navigate(`/vap/all-campaign/${response.data.data[0].campaings[0].uuid}`);
                        setOpen(response.data.data[0].id);
                        setCampaignSelected({
                            campaign: response.data.data[0].campaings[0],
                            sponsor: response.data.data[0]
                        });
                    }
                    dispatch(openDrawer(false));
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        fetchTree()
    }, [campaignId])

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true)
            getCampaignKpi(campaignSelected?.campaign?.uuid, formatedDate(selectedInitialDate), formatedDate(selectedFinalDate))
                .then((response) => {
                    // console.log(response.data.data)
                    setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }
        const fetchCampaign = () => {
            setKpiLoading(true)
            getCampaignFromSponsor(campaignSelected?.sponsor?.uuid)
                .then((response) => {
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        if (campaignSelected?.campaign && campaignId) {
            fetchKpi()
            fetchCampaign()
        }
    }, [campaignSelected, selectedInitialDate, selectedFinalDate])

    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])

    const handleInitialDateChange = (date) => {
        setSelectedInitialDate(date);
    };

    const handleFinalDateChange = (date) => {
        setSelectedFinalDate(date);
    };

    return (
        <Grid container>
            {!drawerOpen && (
                <Grid item xs={12} sm={2} lg={2} >
                    <p className='font-semibold mb-2'>Lista de campañas:</p>
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', mr: 1 }} className={`h-screen ${theme.palette.mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                        <MenuCampaignThread tree={tree} handleListItemClick={handleListItemClick} campaignSelected={campaignSelected} open={open} handleClick={handleClick} />
                    </Box>
                </Grid>)}

            <Grid container xs={12} sm={drawerOpen ? 12 : 10} lg={drawerOpen ? 12 : 10} rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={4}>
                            <Stack direction='row' alignItems="center" spacing={2}>
                                <Stack>
                                    <Typography variant='subtitle1' gutterBottom>Fecha incial:</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                        <DatePicker
                                            minDate={new Date('2024-01-01')}
                                            maxDate={new Date('2030-12-31')}
                                            value={selectedInitialDate}
                                            onChange={(newValue) => handleInitialDateChange(newValue)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack>
                                    <Typography variant='subtitle1' gutterBottom>Fecha final:</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                        <Box>
                                            <DatePicker
                                                minDate={new Date('2024-01-01')}
                                                maxDate={new Date('2030-12-31')}
                                                value={selectedFinalDate}
                                                onChange={(newValue) => handleFinalDateChange(newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Stack direction='row' alignItems="center" spacing={2}>
                                <div className='flex flex-row justify-end w-full py-4 mr-4 space-x-2'>
                                    <Button variant="contained" startIcon={<Call />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleCallsClick}>
                                        Llamadas afectadas
                                    </Button>
                                    <Button variant="contained" startIcon={<Messages3 />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleConversationClick}>
                                        Conversaciones
                                    </Button>
                                    <Button variant="contained" startIcon={<PresentionChart />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleStatsClick}>
                                        Estadisticas
                                    </Button>
                                    <Button variant="contained" startIcon={<Personalcard />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={(e) => {
                                        handleAgentsClick()
                                        e.stopPropagation()
                                    }}>
                                        Agentes
                                    </Button>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                {kpi && <VapDataList data={kpi} theme={theme} loading={kpiLoading} />}

                <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <VapBigChartCard campaignSelected={campaignSelected} selectedInitialDate={selectedInitialDate} selectedFinalDate={selectedFinalDate} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} lg={7}>
                    <Stack spacing={3}>
                        <TopAgentChart campaignSelected={campaignSelected} selectedInitialDate={selectedInitialDate} selectedFinalDate={selectedFinalDate} />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={5}>
                    <Stack spacing={3}>
                        <AiHubActivityCard campaignSelected={campaignSelected} />
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AllCampaign