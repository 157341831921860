import { useEffect, useState } from "react";
import DashboardList from "./DashboardList";
import LeadEmptyView from "./LeadEmptyView";
import useAuth from "hooks/useAuth";

const LeadPage = () => {
    const [boards, setBoards] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { getDashboardList } = useAuth();

    const fetchTree = () => {
        setIsLoading(true)
        getDashboardList()
            .then(response => {
                // console.log(response.data);
                setBoards(response.data.data);
            })
            .catch(error => console.error(error))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (boards.length <= 0) {
            fetchTree()
        }
    }, []);

    if (isLoading || boards.length > 0) {
        return (
            <DashboardList boards={boards} refresh={fetchTree}/>
        )
    } else {
        return (
            <LeadEmptyView />
        );
    }
}

export default LeadPage;

/*import { useEffect, useState } from "react";

const LeadPage = () => {
    const [height, setHeight] = useState(480);

    useEffect(() => {
        const timerID = setInterval(() => {
            const iframe = document.getElementById('iframe')
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            const frameHeight = iframeDoc.body.scrollHeight;
            console.log(frameHeight)
            if(frameHeight !== height) {
                setHeight(frameHeight);
            }
        }, 1000);
    });
    return (
        <iframe
            id='iframe'
            title="Lead Manager IGS"
            //className='w-full h-[80vh]'
            className='w-full'
            height={`${height}px`}
            src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/891376964786/dashboards/8c1ce71e-0891-44eb-8220-68fe9766e6bf?directory_alias=igs-iahub"
        />
    );
}

export default LeadPage;*/