import React from 'react';
import { Slider, Box, Divider, Tooltip, Zoom, CircularProgress, LinearProgress } from '@mui/material';
import { ArrowUp2, Backward10Seconds, Forward10Seconds, PauseCircle, Play, PlayCircle, VolumeCross, VolumeHigh, VolumeLow, VolumeLow1, VolumeUp } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import Avatar from 'components/@extended/Avatar';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import CustomTooltip from 'components/@extended/Tooltip';
import { snackbarClasses } from '@mui/base';

const marks = [
    { value: 0, label: '0:00' },
    { value: 10, label: '0:10' },
    { value: 20, label: '0:20' },
    // Agrega más marcas si es necesario
];

const AudioPlayer = ({ selectedAgent, src, loading, manualChangeTime, handleTimeUpdate, togglePlayPause, isPlaying, setIsPlaying, audioRef }) => {

    const [volume, setVolume] = useState(0.7);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);

    const handleForward = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = Math.min(audioRef.current.duration, audioRef.current.currentTime + 10);
        }
    };

    const handleRewind = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = Math.max(0, audioRef.current.currentTime - 10);
        }
    };


    const handleVolumeChange = (event, newValue) => {
        if (audioRef.current) {
            audioRef.current.volume = newValue / 100;
            setVolume(newValue / 100);
        }
    };

    const handleTimeChange = (event, newValue) => {
        // console.log(newValue)
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
            setCurrentTime(newValue);
        }
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        const audio = audioRef.current;

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurrentTime(audio.currentTime);
        };

        const setAudioTime = () => {
            setCurrentTime(audio.currentTime);
            if (handleTimeUpdate) {
                handleTimeUpdate(audio.currentTime);
            }
        };

        audio.addEventListener('loadeddata', setAudioData);
        audio.addEventListener('timeupdate', setAudioTime);

        return () => {
            audio.removeEventListener('loadeddata', setAudioData);
            audio.removeEventListener('timeupdate', setAudioTime);
        };
    }, []);

    const ensureAudioBase64Prefix = (base64) => {
        if (!base64.startsWith("data:audio/mp3;base64,")) {
            return `data:audio/mp3;base64,${base64}`;
        }
        return base64;
    };

    useEffect(() => {
        // console.log(src);
        if (src) {
            const treatUrl = ensureAudioBase64Prefix(src);
            setAudioUrl(treatUrl);
            //audioRef.current.play().catch(error => console.error("Error al iniciar la reproducción del audio:", error));
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current.src = "";
            }
            setCurrentTime(0);
            setAudioUrl(null);
            setIsPlaying(false);
        }
    }, [src]);


    useEffect(() => {
        if (manualChangeTime && audioRef.current) {
            audioRef.current.currentTime = manualChangeTime;
            setCurrentTime(manualChangeTime);
        }
    }, [manualChangeTime]);

    const VolumeControl = (
        <Box className="flex items-center space-x-2">
            <IconButton aria-label="volume off" onClick={() => handleVolumeChange(null, 0)}>
                <VolumeCross className="text-xl" />
            </IconButton>
            <Slider
                aria-label="Volume"
                value={volume * 100}
                onChange={handleVolumeChange}
                className="w-24"
            />
            <IconButton aria-label="volume up" onClick={() => handleVolumeChange(null, 100)}>
                <VolumeHigh className="text-xl" />
            </IconButton>
        </Box>
    );

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef} src={audioUrl} onEnded={() => setIsPlaying(false)} />

            <Box className="flex flex-col items-center space-y-2 mx-4">
                {/* Momentos relevantes del audio (barras de colores) */}
                {/* <div className='flex flex-row w-full items-center rounded space-x-1'>
                    <Avatar alt="Natacha" type="filled" size="xs" src={selectedAgent?.img || ""}>{selectedAgent?.name.substring(0, 1) || "U"}</Avatar>
                    <Box className="flex flex-row w-full h-3 bg-gray-200 rounded ">
                        <Box className="absolute w-2/12 h-3 left-1/4 bg-green-500 rounded"></Box>
                        <Box className="absolute w-3/12 h-3 left-1/12 bg-blue-500 rounded"></Box>
                        <Box className="absolute w-1/12 h-3 left-1/4 bg-gray-200"></Box>
                        <Box className="absolute w-2 h-3 left-3/4 bg-pink-500 rounded"></Box>
                        <Box className="absolute w-1/12 h-3 left-1/2 bg-yellow-500 rounded"></Box>
                    </Box>
                </div>
                <div className='flex flex-row w-full items-center rounded space-x-1'>
                    <Avatar alt="Natacha" type="outlined" size="xs" color="secondary">MD</Avatar>
                    <Box className="flex flex-row w-full h-3 bg-gray-200 rounded">
                        <Box className="absolute w-2/12 h-3 left-1/4 bg-white "></Box>
                        <Box className="absolute w-3/12 h-3 left-1/12 bg-white"></Box>
                        <Box className="absolute w-1/12 h-3 left-1/4 bg-gray-200"></Box>
                        <Box className="absolute w-1 h-3 left-3/4 bg-white"></Box>
                        <Box className="absolute w-1/12 h-3 left-1/2 bg-white"></Box>
                    </Box>
                </div> */}
                {/* Control del reproductor */}
                <Box className="w-full flex flex-col">
                    {!loading ? (
                        <Slider
                            aria-label="Audio Timeline"
                            value={currentTime}
                            min={0}
                            max={duration}
                            step={0.1}
                            onChange={handleTimeChange}
                            className="w-full"
                        />
                    ) : (
                        <div className='w-full'>
                            <LinearProgress color="secondary" />
                        </div>
                    )}
                    <div className='flex flex-row justify-between'>
                        <div className='flex space-x-2 items-center'>
                            <span className="text-xs">{formatTime(currentTime)}</span>
                        </div>

                        <div className='flex flex-row justify-center items-center'>
                            <IconButton aria-label="backwardsecs" shape="rounded" color="secondary" onClick={handleRewind} disabled={!audioUrl}>
                                <Backward10Seconds size="44" variant="Bold" />
                            </IconButton>
                            <IconButton aria-label="play/pause" shape="rounded" color="secondary" onClick={togglePlayPause} disabled={!audioUrl} >
                                {isPlaying ? <PauseCircle size="60" variant="Bold" /> : <PlayCircle size="60" variant="Bold" />}
                            </IconButton>
                            <IconButton aria-label="forwardsecs" shape="rounded" color="secondary" onClick={handleForward} disabled={!audioUrl}>
                                <Forward10Seconds size="60" variant="Bold" />
                            </IconButton>
                        </div>
                        <div className='flex space-x-2 items-center'>
                            <CustomTooltip title={VolumeControl} color="white" labelColor='#000' placement="top"
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                                TransitionComponent={Zoom}
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -14],
                                                },
                                            },
                                        ],
                                    },
                                }}
                            >
                                <IconButton aria-label="adjust volume" color="secondary" disabled={!audioUrl}>
                                    {volume <= 0 ? (
                                        <VolumeCross />
                                    ) : volume > 0 && volume < 0.50 ? (
                                        <VolumeLow1 />
                                    ) : <VolumeHigh />}
                                </IconButton>
                            </CustomTooltip>
                            <span className="text-xs">{formatTime(duration)}</span>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    );
};

export default AudioPlayer;