import React, { useMemo } from 'react';
import { Button, Badge, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'config';

const AgentList = React.memo(({ agents, handleAgentSelect, selectedAgent, theme }) => {
    const capitalizeName = (name) => {
        if (!name) return name;
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    return (
        <div className='grow flex flex-col space-y-4 mb-1 items-center mt-2'>
            {agents.map((agent, index) => (
                <Button sx={{ m: 0, p: 0 }} key={`${agent.uuid}${index}`} onClick={() => handleAgentSelect(agent)}>
                    <div className='flex flex-col'>
                        <Badge color={agent && agent.audios_count > 0 ? "success" : "warning"} overlap="circular" variant="dot">
                            <Avatar alt={agent?.name || "User img"} type="filled" src={agent?.img}>{agent.name && agent.name.substring(0, 1)}</Avatar>
                        </Badge>
                        <span className={`text-xs text-ellipsis text-nowrap ${selectedAgent?.id === agent?.id ? "text-orange-500" : theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-center overflow-hidden`}>
                            {agent.name && agent.name.length > 7 ? `${capitalizeName(agent.name.substring(0, 7).trim())}.` : `${capitalizeName(agent.name)}.`}
                        </span>
                    </div>
                </Button>
            ))}
        </div>
    );
});

export default AgentList;