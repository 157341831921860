import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { InfoCircle, Sms, Trash } from 'iconsax-react';

const AlertUserResend = ({ open, handleClose, email, resendInvitation }) => {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            <DialogContent sx={{ mt: 2, my: 1 }}>
                <Stack alignItems="center" spacing={3.5}>
                    {/* <Avatar color="warning" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}> */}
                    <Sms variant="Bold" size="60" className='text-yellow-500' />
                    {/* </Avatar> */}
                    <Stack spacing={2}>
                        <Typography variant="h4" align="center">
                            ¿Seguro que desea reenviar la invitación?
                        </Typography>
                        <Typography align="center">
                            <Typography variant="subtitle1" component="span">
                                {email}
                            </Typography>
                        </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                        <Button fullWidth onClick={handleClose} color="secondary" variant="outlined">
                            Cancelar
                        </Button>
                        <Button fullWidth className='bg-yellow-500 hover:bg-yellow-400 text-black font-medium' variant="contained" onClick={resendInvitation} autoFocus>
                            Reenviar Invitación
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default AlertUserResend