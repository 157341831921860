import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
// import AlertCustomerDelete from './AlertCustomerDelete';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Add, Camera, SearchNormal, Trash } from 'iconsax-react';
import useAuth from 'hooks/useAuth';

const avatarImage = require.context('assets/images/users', true);

// constant
const getInitialValues = (sponsor) => {
    // Asumimos valores predeterminados para campos que podrían no estar en `campaign`
    const initialValues = {
        name: '',
        country: '', // Usaremos el nombre común del país
        user_assigned: [], // Mantenemos la lista de usuarios asignados, aunque no se usa directamente en el formulario según tu código
        status: 'ACTIVE', // Estado por defecto, puede ajustarse según tu lógica
    };

    if (sponsor) {
        // console.log(sponsor.name)
        initialValues.name = sponsor.name;
        // initialValues.country = sponsor.country.common_name;
        // initialValues.sponsor = getNameById(sponsor.sponsor_id);
        initialValues.status = sponsor.status;
        // No ajustamos `user_assigned` aquí, ya que tu formulario no parece manejarlo directamente
    }

    return initialValues;
};

const NewSponsor = ({ customer, onCancel, open, setData, data, updateSponsor, setCustomer }) => {
    const theme = useTheme();
    const isCreating = !customer;
    const { getCountries, createSponsor, searchSponsorUser, editSponsor, getEgroupsList } = useAuth();
    const [countries, setCountries] = useState([])
    const [egroups, setEgroups] = useState([])
    // console.log(customer)
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [avatar, setAvatar] = useState();
    const [initialValuesState, setInitialValuesState] = useState({
        name: customer?.name || '',
        country: customer?.country?.id || '',
        egroup: customer?.economic_group_id || '',
        status: customer?.status || 'ACTIVE',
        user_assigned: []
    })
    const [sponsorData, setSponsorData] = useState(null)
    const [userAssignedIds, setUserAssignedIds] = useState([]);
    const [userAssignedDetails, setUserAssignedDetails] = useState([]);
    const [email, setEmail] = useState('');
    const [userAssigned, setUserAssigned] = useState([]);
    const [searchError, setSearchError] = useState('');
    const [removedUserIds, setRemovedUserIds] = useState([]);

    // console.log(customer)

    useEffect(() => {
        if (customer) {
            // console.log(data)
            // console.log(customer?.id)
            const sponsor = data.find(s => s.id === customer?.id);
            setSponsorData(sponsor)
            // console.log(sponsor)
            const userIds = sponsor?.user_assigned.map(user => {
                if (!userAssignedIds.includes(user.id)) {
                    return user.id;
                }
            });
            setInitialValuesState({
                name: sponsor?.name || '',
                country: sponsor?.country_id || '',
                egroup: sponsor?.economic_group_id || '',
                status: sponsor?.status || 'ACTIVE',
            })
            // setUserAssignedIds(userIds);
            setUserAssignedDetails(sponsor?.user_assigned || []);
            setAvatar(sponsor?.logo || "");
        } else {
            formik.resetForm()
            setInitialValuesState({
                name: '',
                country: '',
                status: 'ACTIVE',
            })
            setAvatar("");
            setUserAssignedDetails([]);
        }
    }, [customer])


    useEffect(() => {
        if (selectedImage) {
            setAvatar(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    useEffect(() => {
        const fetchCountries = () => {
            getCountries()
                .then((response) => {
                    // console.log(response)
                    setCountries(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {

                })
        }

        const fetchEgroups = () => {
            getEgroupsList()
                .then((response) => {
                    // console.log(response)
                    setEgroups(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {

                })
        }

        if (open) {
            fetchCountries()
            fetchEgroups()
        }
    }, [open])

    const [openAlert, setOpenAlert] = useState(false);

    const handleAlertClose = () => {
        setOpenAlert(!openAlert);
        onCancel();
    };

    const handleStatusChange = (event) => {
        formik.setFieldValue('status', event.target.checked ? 'ACTIVE' : 'INACTIVE');
    };

    const handleSearch = async () => {
        try {
            if (email === null || email === "" || email === undefined) {
                setSearchError('Ingrese un email válido.');
                return;
            }

            const response = await searchSponsorUser(email);
            const userData = response.data.data;
            console.log(response);
            if (userData) {

                if (userAssignedIds.includes(userData.id)) {
                    setSearchError('El usuario ya está asignado.');
                    return;
                }

                setUserAssignedIds(prevIds => [...prevIds, userData.id]);
                setUserAssignedDetails(prevDetails => [...prevDetails, userData]);
                setFieldValue('user_assigned', [...userAssignedIds, userData.id]);
                setEmail('');
                setSearchError('');
            } else {
                setSearchError('Usuario no encontrado.');
            }
        } catch (error) {
            console.error('Error buscando usuario:', error);
            setSearchError(error.message);
        }
    };

    const handleDeleteUser = (userToDelete) => {
        const newUserAssignedIds = userAssignedIds.filter(id => id !== userToDelete.id);
        const newUserAssignedDetails = userAssignedDetails.filter(user => user.id !== userToDelete.id);

        if (customer) {
            if (!removedUserIds.includes(userToDelete.id)) {
                setRemovedUserIds(prevIds => [...prevIds, userToDelete.id]);
            }
        }

        setUserAssignedIds(newUserAssignedIds);
        setUserAssignedDetails(newUserAssignedDetails);
        setFieldValue('user_assigned', newUserAssignedIds);
    };

    useEffect(() => {
        if (customer?.logo) {
            setAvatar(customer.logo);
        }
    }, [customer?.logo]);

    const formik = useFormik({
        initialValues: initialValuesState,
        enableReinitialize: true,
        validationSchema: customer ? Yup.object({
            name: Yup.string().required('Nombre del sponsor requerido'),
            country: Yup.number().required('ID del país es requerido'),
        }) : Yup.object({
            name: Yup.string().required('Nombre del sponsor requerido'),
            country: Yup.number().required('ID del país es requerido'),
            user_assigned: Yup.array()
                .of(Yup.number().positive('ID del usuario debe ser positivo').required('ID del usuario es requerido'))
                .min(1, 'Debe haber al menos un usuario asignado')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                if (customer) {
                    let formData = new FormData();
                    if (selectedImage) formData.append("logo", selectedImage);
                    formData.append("name", values.name)
                    if (values.user_assigned) {
                        for (let i = 0; i < values.user_assigned.length; i++) {
                            formData.append('user_assigned[]', values.user_assigned[i]);
                        }
                    }
                    formData.append("country_id", values.country)
                    formData.append("economic_group_id", values.egroup)
                    formData.append("status", values.status)

                    if (removedUserIds.length > 0) {
                        removedUserIds.forEach(id => formData.append('delete_user_assigned[]', id));
                    }

                    editSponsor(sponsorData?.uuid, formData)
                        .then((response) => {
                            // console.log(response.data)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Sponsor editado exitosamente',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: false
                                })
                            );
                            updateSponsor(response.data.data)
                            resetForm();
                        })
                        .catch((error) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: false
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();
                    if (selectedImage) formData.append("logo", selectedImage);
                    formData.append("name", values.name)
                    for (let i = 0; i < values.user_assigned.length; i++) {
                        formData.append('user_assigned[]', values.user_assigned[i]);
                    }
                    formData.append("country_id", values.country)
                    formData.append("economic_group_id", values.egroup)
                    formData.append("status", values.status)
                    //console.log(values)
                    createSponsor(formData)
                        .then((response) => {
                            // console.log(response.data)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Sponsor creado exitosamente',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: false
                                })
                            );
                            setData(response.data.data)
                            //setData();
                            resetForm();
                        })
                        .catch((error) => {
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: error.message,
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: false
                                })
                            )
                        })
                        .finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                }
                setSubmitting(false);
                onCancel();
            } catch (error) {
                // console.log(error)
            }
        }
    });

    const handleCancel = () => {
        formik.resetForm()
        setCustomer()
        onCancel()
    }

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(event.target.files[0]);
        }
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? 'Editar sponsor' : 'Nuevo sponsor'}</DialogTitle>
                        <Divider />
                        <DialogContent sx={{ p: 2.5 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                                        <FormLabel
                                            htmlFor="change-avtar"
                                            sx={{
                                                position: 'relative',
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                '&:hover .MuiBox-root': { opacity: 1 },
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: '1px dashed' }} src={avatar} />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                                                    width: '100%',
                                                    height: '100%',
                                                    opacity: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Stack spacing={0.5} alignItems="center">
                                                    <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                                                    <Typography sx={{ color: 'secondary.lighter' }}>Cargar</Typography>
                                                </Stack>
                                            </Box>
                                        </FormLabel>
                                        <TextField
                                            type="file"
                                            id="change-avtar"
                                            placeholder="Outlined"
                                            variant="outlined"
                                            sx={{ display: 'none' }}
                                            onChange={(e) => setSelectedImage(e.target.files?.[0])}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="name">Nombre del sponsor</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name='name'
                                                    id="name"
                                                    placeholder="Escriba el nombre del sponsor"
                                                    {...getFieldProps('name')}
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-location">País</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        fullWidth
                                                        id="campaign-country"
                                                        {...getFieldProps('country')}
                                                        error={Boolean(touched.country && errors.country)}
                                                        helperText={touched.country && errors.country}
                                                        onChange={(event) => setFieldValue('country', event.target.value)}
                                                    >
                                                        {countries.map((country) => (
                                                            <MenuItem key={country.id} value={country.id}>
                                                                {country.common_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {touched.orderStatus && errors.orderStatus && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                        {errors.orderStatus}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-location">Grupo económico</InputLabel>
                                                <FormControl fullWidth>
                                                    <Select
                                                        fullWidth
                                                        id="campaign-egroup"
                                                        {...getFieldProps('egroup')}
                                                        error={Boolean(touched.egroup && errors.egroup)}
                                                        helperText={touched.egroup && errors.egroup}
                                                        onChange={(event) => setFieldValue('egroup', event.target.value)}
                                                    >
                                                        {egroups.map((egroup) => (
                                                            <MenuItem key={egroup.id} value={egroup.id}>
                                                                {egroup.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {touched.orderStatus && errors.orderStatus && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                        {errors.orderStatus}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-email">Asignar usuarios con acceso</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    label="Asignar usuario por correo electrónico"
                                                    variant="outlined"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    error={!!searchError}
                                                    helperText={searchError}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <Button onClick={handleSearch} variant="contained" className="bg-orange-500 text-nowrap px-1">
                                                               Asignar
                                                            </Button>
                                                        ),
                                                    }}
                                                />
                                                <Stack direction="row" spacing={1} flexWrap="wrap">
                                                    {userAssignedDetails.map((user) => (
                                                        <Chip
                                                            key={user.id}
                                                            avatar={<Avatar alt={user.name || "Usuario"} src={user.profile_img || undefined} />}
                                                            label={`${user.name || "Usuario"} (${user?.user_type?.name || ""})`}
                                                            onDelete={() => handleDeleteUser(user)}
                                                        />
                                                    ))}
                                                </Stack>
                                                {touched.user_assigned && errors.user_assigned && (
                                                    <FormHelperText error>{errors.user_assigned}</FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                                <Stack spacing={0.5}>
                                                    <Typography variant="subtitle1">¿Activar este sponsor?</Typography>
                                                    <Typography variant="caption" color="textSecondary">
                                                        El sponsor estará activo una vez creado
                                                    </Typography>
                                                </Stack>
                                                {/*<FormControlLabel control={<Switch defaultChecked sx={{ mt: 0 }} {...getFieldProps('status')}/>} label="" labelPlacement="start" />*/}
                                                {/*<FormControlLabel control={<Switch sx={{ mt: 0 }} {...getFieldProps('status')}/>} label="" labelPlacement="start" />*/}
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={formik.values.status === 'ACTIVE'}
                                                            onChange={handleStatusChange}
                                                            sx={{ mt: 0 }}
                                                        />
                                                    }
                                                    label=""
                                                    labelPlacement="start"
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {!isCreating && (
                                        <Tooltip title="Delete Customer" placement="top">
                                            <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                                                <Trash variant="Bold" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleCancel}>
                                            Cancelar
                                        </Button>
                                        <Button type="submit" variant="contained" disabled={isSubmitting} className="bg-orange-500">
                                            {customer ? 'Editar sponsor' : 'Agregar sponsor'}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
            {/* {!isCreating && <AlertCustomerDelete title={customer.fatherName} open={openAlert} handleClose={handleAlertClose} />} */}
        </>
    );
}

export default NewSponsor