import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
  AvatarGroup,
  Chip
} from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import Transitions from 'components/@extended/Transitions';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| CUSTOMER - VIEW ||============================== //

const StatusComponent = ({value}) => {
    switch (value) {
        case 'FINISHED':
            return <Chip label="Finalizado" className="bg-[#06BF7B21] text-green-500" size="small" />
        case 'ACTIVE':
            return <Chip label="Activo" className="bg-[#06BF7B21] text-green-500" size="small" />
        case 'INACTIVE':
            return <Chip label="Inactivo" className="bg-[#FF414121] text-[#FF4141]" size="small" />
        default:
            return <Chip label="Indefinido" className="bg-[#9399AA21] text-slate-800" size="small" />
    }
}

const EGroupView = ({ data }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(data)

  return (
    <TableRow sx={{ '&:hover': { bgcolor: `transparent !important` }, overflow: 'hidden' }}>
      <TableCell colSpan={9} sx={{ p: 2.5, overflow: 'hidden' }}> {/* Asegúrate de ajustar el colSpan según el número de columnas de tu tabla */}
        <Transitions type="slide" direction="down" in={true}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
              <MainCard>
                {/*<Chip
                  label={data.status === 'active' ? 'Activo' : 'Pendiente'}
                  size="small"
                  color={data.status === 'active' ? "success" : "warning"}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    fontSize: '0.675rem',
                    color: "white",
                    fontWeight: "bold",
                  }}
                />*/}
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={2.5} alignItems="center">
                      <Avatar alt="Avatar 1" size="sm" src={data.logo} />
                      <Stack spacing={0.5} alignItems="center">
                        <Typography variant="h5">{data.name}</Typography>
                        {/*<Typography color="secondary">{data.count_campain ? data.count_campain : 0} campaña(s)</Typography>*/}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                          <Stack spacing={0.5}>
                            <StatusComponent value={data.status} />
                          </Stack>
                        </Grid>
                </Grid>
              </MainCard>
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={8} xl={9}>
              <Stack spacing={2.5}>
                <MainCard title="Detalles Adicionales">
                  <List sx={{ py: 0 }}>
                    {/* Aquí podrías añadir más detalles personales si los tienes */}
                    <ListItem>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={0.5}>
                            <Typography color="secondary">Sponsors</Typography>
                            <div className='flex flex-col items-start'>
                              <AvatarGroup max={3}>
                              {data.sponsors.map(sponsor => (
                                      <Avatar key={sponsor.id} alt={sponsor.name} src={sponsor.logo} />
                                  ))}
                              </AvatarGroup>
                            </div>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack spacing={0.5}>
                            <Typography color="secondary">País</Typography>
                            <Typography>{data.country.common_name}</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>
                {/* Considera agregar más tarjetas o secciones con información relevante sobre el usuario */}
              </Stack>
            </Grid>
          </Grid>
        </Transitions>
      </TableCell>
    </TableRow>
  );
};

EGroupView.propTypes = {
  data: PropTypes.object
};

export default EGroupView;
