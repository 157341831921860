import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Avatar, Skeleton, Button, Badge, Chip, Divider } from '@mui/material';
import { ThemeMode } from 'config';
// assets
import { ArrowDown, ArrowDown2, ArrowUp, ArrowUp2, Calendar, Tag2, CloudChange, Add, Messages3, PresentionChart, Personalcard, TickCircle, Call } from 'iconsax-react';
import VapBigChartCard from 'sections/widget/dashboard/VapBigChartCard';
import TopAgentChart from 'sections/widget/dashboard/TopAgentChart';
import ProcessStatusCard from 'sections/widget/dashboard/ProcessStatusCard';
import AiHubActivityCard from 'sections/widget/dashboard/AiHubActivityCard';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import useAuth from 'hooks/useAuth';
import VapDataCard from 'components/cards/dashboard/VapDataCard';
import VapStatusChart from 'sections/widget/dashboard/VapStatusChart';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MenuCampaignThread from './MenuCampaignThread';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import MenuCampaignPureCss from './MenuCampaignPureCss';
import MainCard from 'components/MainCard';
import AgentStatsRadialChart from 'pages/vap/AgentStatsRadialChart';
import useDataStore from 'store/useDataStore';
import LoadingAgent from 'pages/vap/LoadingAgent';
import CampaignStatTable from './CampaignStatTable';
const avatarImage = require.context('assets/images/users', true);

const CampaignStats = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { campaignId, agentId } = useParams();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { getCampaignKpi, getCampaignsAndSponsorTree, getCampaignFromSponsor, getCampaignRecomendation, getCampaignTopAgents, getCampaignStats } = useAuth();
    const { getCampaignAgents, getAudioFromAgentCampaign, getAudioData, getAudioFile } = useAuth();
    const { campaignSelected, setCampaignSelected, selectedData, setSelectedData } = useDataStore();
    const [campaignSelectedName, setCampaignSelectedName] = useState("")
    const [tree, setTree] = useState([]);
    const [campaignStats, setCampaignStats] = useState([])
    const [chartData, setChartData] = useState([0, 0, 0, 0])
    const [kpi, setKpi] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null)
    const [recomendations, setRecomendations] = useState([])
    const [agents, setAgents] = useState([])
    const [loadingAgent, setLoadingAgent] = useState(false)
    const [kpiLoading, setKpiLoading] = useState(true)
    const [loadingAudioData, setLoadingAudioData] = useState(false)
    const [open, setOpen] = useState(null);
    const [audioData, setAudioData] = useState(null)
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsor', path: `/vap/${campaignSelected?.sponsor.uuid || ""}` },
            { label: campaignSelected.sponsor.name || "", path: `/vap/${campaignSelected?.sponsor.uuid || ""}` },
            { label: 'Campañas', path: '/vap/all-campaign' },
            { label: campaignSelected.campaign.name || "", path: `/vap/all-campaign/${campaignSelected?.campaign.uuid || ""}` },
            { label: "Estadisticas de Campaña", path: `/vap/all-campaign/stats/${campaignSelected?.campaign.uuid || ""}` },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, campaignSelected]);

    const handleClick = (id) => {
        setOpen(open !== id ? id : '');
    };

    const handleAgentSelect = (agent) => {
        // console.log(agent)
        // setAudios(null)
        setSelectedAgent(agent)
    }

    const handleListItemClick = (event, campaign, sponsor) => {
        navigate(`/vap/all-campaign/${campaign?.uuid}`);
        setCampaignSelected({
            campaign: campaign,
            sponsor: sponsor
        });
    };

    const capitalizeName = (name) => {
        if (!name) return name;
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    useEffect(() => {
        const fetchTree = () => {
            setKpiLoading(true)
            getCampaignsAndSponsorTree()
                .then((response) => {
                    // console.log(response.data)
                    setTree(response.data.data)
                    // console.log(campaignId)
                    if (campaignId) {
                        let found = false;
                        let foundCampaign = null;
                        let foundSponsor = null;
                        for (const sponsor of response.data.data) {
                            if (campaignId.length > 5) {
                                foundCampaign = sponsor.campaings.find(campaign => campaign.uuid.toString() === campaignId);
                            } else {
                                foundCampaign = sponsor.campaings.find(campaign => campaign.id.toString() === campaignId);
                            }
                            if (foundCampaign) {
                                foundSponsor = sponsor
                                setCampaignSelected({ campaign: foundCampaign, sponsor: sponsor });
                                setOpen(sponsor.id);
                                found = true;
                                break;
                            }
                        }

                        if (found) {
                            // console.log(foundCampaign)
                            setCampaignSelected({ campaign: foundCampaign, sponsor: foundSponsor });
                            setOpen(foundSponsor.id);
                        } else {
                            // console.log(response.data.data[0].campaings[0].uuid);
                            //setCampaignSelected(response.data.data[0].campaings[0].uuid);
                            setCampaignSelected({
                                campaign: response.data.data[0].campaings[0],
                                sponsor: response.data.data[0]
                            });
                        }
                    } else {
                        // console.log(response.data.data[0].campaings[0].uuid);
                        navigate(`/vap/all-campaign/${response.data.data[0].campaings[0].uuid}`);
                        setOpen(response.data.data[0].id);
                        // setCampaignSelected(response.data.data[0].campaings[0].uuid);
                        setCampaignSelected({
                            campaign: response.data.data[0].campaings[0],
                            sponsor: response.data.data[0]
                        });
                    }
                    dispatch(openDrawer(false));
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }
        // if (!campaignSelected?.campaign) {
        fetchTree()
        // }
    }, [])

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true)
            getCampaignKpi(campaignSelected?.campaign?.uuid)
                .then((response) => {
                    // console.log(response.data.data)
                    setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }
        // const fetchCampaign = () => {
        //     setKpiLoading(true)
        //     getCampaignFromSponsor(campaignSelected?.sponsor?.uuid)
        //         .then((response) => {
        //             // console.log(response.data)
        //             // setCampaign(response.data.data.campaings)
        //         }).catch((error) => {
        //             console.error(error)
        //         }).finally(() => {
        //             setKpiLoading(false)
        //         })
        // }

        const fetchStats = () => {
            setKpiLoading(true)
            getCampaignStats(campaignSelected?.campaign?.uuid)
                .then((response) => {
                    // console.log(response.data.data)
                    setCampaignStats(response.data.data)
                    // setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        // if (campaignSelected?.campaign) {
        fetchKpi()
        fetchStats()
        // fetchCampaign()
        // }
        // console.log(campaignSelected)
    }, [campaignSelected])

    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])

    const handleConversationClick = () => {
        navigate(`/vap/voice-analyzer/${campaignSelected?.campaign?.uuid}`);
    }

    const handleCallsClick = () => {
        navigate(`/vap/campaigns/calls/${campaignSelected?.campaign?.uuid}`);
    }

    const handleAgentsClick = () => {
        // console.log(campaignSelected.campaign)
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
                <Stack direction='row' alignItems="center" spacing={2}>
                    <div className='flex flex-row justify-end w-full py-4 mr-4 space-x-2'>
                        <Button variant="contained" startIcon={<Call />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleCallsClick}>
                            Llamadas afectadas
                        </Button>
                        <Button variant="contained" startIcon={<Messages3 />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleConversationClick}>
                            Conversaciones
                        </Button>
                        <Button variant="contained" startIcon={<PresentionChart />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold'>
                            Estadisticas
                        </Button>
                        <Button variant="contained" startIcon={<Personalcard />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={(e) => {
                            handleAgentsClick()
                            e.stopPropagation()
                        }}>
                            Agentes
                        </Button>
                    </div>
                </Stack>
            </Grid>
            <Grid item xs={12} md={9} lg={9} className={`border-[0.3px] px-2 py-1 rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} mt-2 h-max ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                <CampaignStatTable data={campaignStats} loading={kpiLoading} />
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
                <MainCard >
                    <div className='flex flex-col justify-center '>
                        <div className='flex flex-row'>
                            <span className='text-base mr-1 text-center'>KPIS</span>
                            <span className='text-base text-center font-bold'>Campaña</span>
                        </div>
                    </div>
                    <div className='flex flex-col mt-2'>
                        <div className='flex flex-row items-center mb-3'>
                            {/* <Badge color="success" overlap="circular" variant="dot"> */}
                            <Avatar alt="Natacha" type="filled" size="md" src={campaignSelected?.campaign?.logo || ""} >{campaignSelected?.campaign?.name?.substring(0, 1) || ""}</Avatar>
                            {/* </Badge> */}
                            <div className='flex flex-col ml-2'>
                                <span className={`text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-start mt-1`}>{campaignSelected?.campaign?.name || ""}</span>
                                <span className='text-sm text-orange-500 text-start'>{campaignSelected?.sponsor?.name || ""}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between mt-4 mb-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Llamadas auditadas</p>
                            </div>
                            <div className='flex flex-col'>
                                {kpi ? (
                                    <p className='text-sm font-semibold'>{kpi?.total_archives_process ? Math.trunc(kpi?.total_archives_process) : 0}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Llamadas afectadas</p>
                            </div>
                            <div className='flex flex-col'>
                                {kpi ? (
                                    <p className='text-sm font-semibold'>{Math.trunc(kpi?.total_calls_affected)}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Aceptación de compra (MAC)</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {kpi ? (
                                    <p className='text-sm font-semibold'>{Math.trunc(kpi?.total_buy_accepted)?.toFixed(1)}%</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Calidad de los audios</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {kpi ? (
                                    <p className='text-sm font-semibold'>{`${kpi?.call_quality ? kpi?.call_quality?.toFixed(1) : 0}%`}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className='flex flex-row justify-between my-3'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>Minutos TMO Promedio</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {kpi ? (
                                    <p className='text-sm font-semibold'>{`${kpi?.tmo ? Math.trunc(kpi.tmo) : 0}`}min</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div>
                    </div>
                </MainCard>
            </Grid>
        </Grid>
    );
}

export default CampaignStats