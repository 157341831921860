// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Story, Fatrows, PresentionChart, User, Data, VolumeMute, Category, Personalcard, People, Sound } from 'iconsax-react';
import useDataStore from 'store/useDataStore';
const state = useDataStore.getState();

const icons = {
    user: User,
    sponsor: Data,
    campaign: VolumeMute,
    dashboard: Category,
    widgets: Story,
    statistics: Story,
    data: Fatrows,
    chart: PresentionChart,
    agent: Personalcard,
    egroup: People,
    vap: Sound
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

export const leaditems = [{
    id: 'group-widget',
    title: "MENÚ",
    icon: icons.widgets,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/lead',
            icon: icons.dashboard
        },
    ]
},
{
    id: 'group-widget',
    title: "OTRAS APLICACIONES",
    icon: icons.widgets,
    type: 'group',
    children: [
        {
            id: 'vap',
            title: <FormattedMessage id="vap" />,
            type: 'item',
            url: '/vap',
            icon: icons.vap
        },
    ]
}
    // {
    //     id: 'group-business',
    //     title: "GESTIÓN DE NEGOCIO",
    //     icon: icons.widgets,
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'users',
    //             title: <FormattedMessage id="usuarios" />,
    //             type: 'item',
    //             url: '/users/list',
    //             icon: icons.user,
    //         },
    //         {
    //             id: 'sponsor',
    //             title: <FormattedMessage id="sponsor" />,
    //             type: 'item',
    //             url: '/sponsors',
    //             icon: icons.sponsor
    //         },
    //         {
    //             id: 'campaign',
    //             title: <FormattedMessage id="campaign" />,
    //             type: 'item',
    //             url: '/campaigns/list',
    //             icon: icons.campaign
    //         },
    //         {
    //             id: 'agents',
    //             title: <FormattedMessage id="agent" />,
    //             type: 'item',
    //             url: '/agents/list',
    //             icon: icons.agent
    //         },
    //     ]
    // }
]