import React from 'react'
import { useState } from 'react';
import { useEffect } from "react";
import { useRef } from "react";
import useDataStore from 'store/useDataStore';

const LeadBoard = () => {
    const defaultBoard = "https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/891376964786/dashboards/4e0fca4b-ffa4-4ab9-b7f7-e685e341580e?directory_alias=igs-iahub"
    const [board, setBoard] = useState(null)
    const { boardUrl } = useDataStore()
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        const handleResize = () => {
            if (iframe) {
                iframe.style.height = `${window.innerHeight - 80}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // console.log(boardUrl)
        if (boardUrl) {
            setBoard(boardUrl)
        } else {
            setBoard(defaultBoard)
        }
    }, [boardUrl])


    return (
        <div className="w-full overflow-hidden">
            <iframe
                ref={iframeRef}
                title="Lead Manager IGS"
                className="w-full border-none"
                src={board}>
            </iframe>
        </div>
    )
}

export default LeadBoard