import { Button } from '@mui/material';
import { Add } from 'iconsax-react';
import React from 'react'
import { useNavigate } from 'react-router';

const LeadEmptyView = () => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col justify-center items-center h-full mx-[20%] my-auto'>
            <h1 className='text-2xl font-semibold mb-2'>Aún no hay dashboard creado</h1>
            <p className='text-lg text-center mb-6'>Te invitamos a crear tu primer dashboard, subiendo el archivo SCV, o XLS para para visualizar y analizar datos.</p>
            <Button
                type="submit"
                variant="contained"
                className="bg-orange-500"
                onClick={() => navigate("/lead-upload")}
                startIcon={<Add />}
            >
                Crear nuevo Dashboard
            </Button>
        </div>
    )
}

export default LeadEmptyView