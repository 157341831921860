import { Button, Dialog, DialogContent, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';

import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import dislike from 'assets/images/vap/dislike.png';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/reducers/snackbar';

const AlertCallRejected = ({ id, open, refresh, handleClose }) => {
    const { getFeedbackList, createAudioFeedback } = useAuth();
    const [feedbackList, setFeeedbackList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchFeedback = () => {
            getFeedbackList()
                .then((response) => {
                    setFeeedbackList(response.data.data);
                }).catch((error) => {
                    console.error(error);
                });
        };
        if (feedbackList.length <= 0) {
            fetchFeedback();
        }
    }, [feedbackList.length, getFeedbackList]);

    const RejectSchema = Yup.object().shape({
        text: Yup.number().positive().required('El motivo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            text: '',
        },
        enableReinitialize: true,
        validationSchema: RejectSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                const object = {
                    agent_audio_data_id: id,
                    text_call_feedback_id: values.text,
                    status: 'rejected'
                };

                console.log("Submit! ",object)

                createAudioFeedback(object)
                    .then((response) => {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Llamada reprobada exitosamente.',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                        refresh();
                        resetForm();
                    }).catch((error) => {
                        console.error(error);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Ha ocurrido un error al reprobar la llamada.',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                    }).finally(() => {
                        setSubmitting(false);
                        handleClose();
                    });
            } catch (error) {
                console.error(error);
                setSubmitting(false);
            }
        }
    });

    const handleCancel = () => {
        formik.resetForm();
        handleClose();
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            keepMounted
            TransitionComponent={PopupTransition}
            maxWidth="xs"
            aria-labelledby="column-delete-title"
            aria-describedby="column-delete-description"
        >
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogContent sx={{ mt: 2, my: 1 }}>
                            <Stack alignItems="center" spacing={3.5}>
                                <Avatar color="error" sx={{ width: 72, height: 72, fontSize: '1.75rem' }} src={dislike} />
                                <Stack spacing={2}>
                                    <Typography variant="h4" align="center">
                                        ¿Por qué motivo desea reprobar la llamada?
                                    </Typography>
                                    <Stack>
                                        <InputLabel htmlFor="text">Seleccione un motivo</InputLabel>
                                        <FormControl fullWidth error={Boolean(touched.text && errors.text)}>
                                            <Select
                                                fullWidth
                                                id="text"
                                                {...getFieldProps('text')}
                                                onChange={(event) => setFieldValue('text', event.target.value)}
                                            >
                                                {feedbackList.map((feedback) => (
                                                    <MenuItem key={feedback.id} value={feedback.id}>
                                                        {feedback.text_feedback}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {touched.text && errors.text && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                                                    {errors.text}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                                    <Button fullWidth onClick={handleCancel} color="secondary" variant="outlined">
                                        Cancelar
                                    </Button>
                                    <Button fullWidth type="submit" variant="contained" disabled={isSubmitting} className="bg-orange-500">
                                        Reprobar
                                    </Button>
                                </Stack>
                            </Stack>
                        </DialogContent>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </Dialog>
    );
};

export default AlertCallRejected;