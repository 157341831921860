import PropTypes from 'prop-types';

// material-ui
import { Typography, Stack, CardMedia } from '@mui/material';

// project-import
import { DropzopType } from 'config';

// assets
import { Camera } from 'iconsax-react';
import UploadCover from 'assets/images/upload/upload.svg';
import filesPlaceholder from 'assets/images/icons/filesPlaceholder.svg';

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function LeadPlaceHolderContent({ type }) {
  return (
    <>
      {type !== DropzopType.standard && (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
          <CardMedia component="img" image={filesPlaceholder} sx={{ width: 100 }} />
          <Stack sx={{ p: 3 }} spacing={1}>
            <Typography className='text-sm font-semibold'>Arrastra o selecciona un archivo en formato SCV, XLS</Typography>

            <Typography color="secondary">
              Arrastra el archivo aqui o pulsa&nbsp;
              <Typography component="span" color="primary" sx={{ textDecoration: 'underline' }}>
                Buscar
              </Typography>
              &nbsp;para examinar tu equipo.
            </Typography>
          </Stack>
        </Stack>
      )}
      {type === DropzopType.standard && (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Camera style={{ fontSize: '32px' }} />
        </Stack>
      )}
    </>
  );
}