import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export function formatDateString(dateStr) {
    try {
        const date = parseISO(dateStr);
        return format(date, 'dd MMM, yyyy', { locale: es });
    } catch(err){
        return dateStr
    }
}

export function formatRelativeDate(dateString) {
    try {
        const date = parseISO(dateString); // Convierte la fecha ISO string en un objeto Date
        let result = formatDistanceToNow(date, { addSuffix: true, locale: es }); // Formato estándar

        // Elimina palabras comunes que no deseas en el resultado
        result = result.replace('alrededor de ', ''); // Elimina 'alrededor de'
        result = result.replace('menos de un', 'un'); // Cambia 'menos de un' por 'un'
        result = result.replace('casi', ''); // Elimina 'casi'

        return result;
    } catch (error) {
        return dateString;
    }
}