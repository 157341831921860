import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Button } from '@mui/material';
import { ArrowDown2, ArrowUp2, Play } from 'iconsax-react';
import './styles.css'
import Avatar from 'components/@extended/Avatar';
import { useEffect } from 'react';

const MenuCampaignThread = ({ tree, handleListItemClick, campaignSelected, open, handleClick, chip = true }) => {

    return (
        <List component="nav" aria-labelledby="nested-list-subheader" className='p-0'>
            {tree.map((sponsor) => (
                <React.Fragment key={sponsor.id}>
                    <ListItem button onClick={() => handleClick(sponsor.id)} className="sponsor-list-item rounded-xl mb-[10px] border-[5px]" selected={open === sponsor.id}>
                        <Avatar size={"sm"} src={sponsor.logo} alt={sponsor.name} className="sponsor-logo" />
                        <ListItemText primary={sponsor.name} className="ml-2" />
                        {sponsor.count_campain && chip && <div className="count-badge text-xs mr-2">{sponsor.count_campain}</div>}
                        {open === sponsor.id ? <ArrowUp2 size={18} /> : <ArrowDown2 size={18} />}
                    </ListItem>
                    <Collapse in={open === sponsor.id} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className="campaign-list">
                            {sponsor.campaings.map((campaign) => (
                                <ListItem key={campaign.id} className="campaign-list-item hover:cursor-pointer">
                                    <div className="campaign-connector"></div>
                                    <ListItemText
                                        primary={campaign.name}
                                        className={`${campaignSelected.campaign.uuid === campaign.uuid ? "text-orange-500" : ""} text-ellipsis overflow-clip`}
                                        onClick={(event) => handleListItemClick(event, campaign, sponsor)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ))}
        </List>
    );
};

export default MenuCampaignThread;
